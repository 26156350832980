$primary-nav-width: 33.3%;
$color-tan: #a2998b;
$color-blue: #2a8ac8;
$legacy-support-for-ie6: false;
$legacy-support-for-ie7: false;
$legacy-support-for-ie8: true;

#primary-nav .level-1 {
    width: auto;
    @include breakpoint($sm) {
        padding: 8px 20px;
    }
}

.title-bar {
    font-size: 22px;
}

.brand__lockup img {
    height: 84px;
}

h1, h2, h3, h4, h5, h6 {
    font-family: $wcm-regular;
}
///////////////////////////////////
/**
 * Vertical Centering
 *
 * Apply this to container. Inner content, to be vertical centered, must
 * get display: inline-block.
 * Container must have a defined height. Possibly use absolute positioning.
 */
@mixin vertical-center-container {
  &:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -0.25em; /* Adjusts for spacing */
  }
}
@mixin vertical-center-content {
  display: inline-block;
  vertical-align: middle;
}

@mixin icon-after($character) {
  &:after {
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: none;

    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: .2em;
    text-align: center;

    font-variant: normal;
    text-transform: none;

    line-height: 1em;

    margin-left: .2em;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    content: $character;
  }
  &:hover, &:active {
    &:after {
      text-decoration: none;
    }
  }
}

@mixin flex-columns {
  display: block;
  display: -webkit-flex;
//  display: -ms-flexbox;
  display: flex;

  -webkit-flex-wrap: wrap;
//  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

@mixin font-size($size:1.6, $line: $size * 1.5){
  font-size:   ($size * 10) + px;
  font-size:   $size + rem;
}

@mixin element-invisible {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  @if $legacy-support-for-ie6 or $legacy-support-for-ie7 {
    clip: rect(1px 1px 1px 1px); // IE6 and IE7 use the wrong syntax.
  }
  clip: rect(1px, 1px, 1px, 1px);
}

// Turns off the element-invisible effect.
@mixin element-invisible-off {
  position: static !important;
  clip: auto;
  height: auto;
  width: auto;
  overflow: auto;
}

// Makes an element visually hidden by default, but visible when focused.
@mixin element-focusable {
  @include element-invisible;

  &:active,
  &:focus {
    @include element-invisible-off;
  }
}

// Webfonts are difficult to deal with
@mixin font-antialiasing($value: on) {
  @if $value == on {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  @else {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
  }
}

$mobile: (max-width 767px);
//$narrow: (width 768px 991px), (max-device-width 768px) (min-width 600px) (orientation portrait);
//$desktop: (min-width 992px), (max-device-width 1024px) (min-width 1024px) (orientation landscape);
//$narrow: (min-width: 768px and max-width: 991px) and (max-device-width: 768px) and (min-width: 600px) and (orientation: portrait);
//$mobile: (max-width: 767px);
$narrow: (min: 768px) and (max: 991px); //,(max-device-width:768px) and (min-width:600px) and (orientation:portrait);
$desktop: (min: 992px); //,(max-device :1024px) and (min :1024px) and (orientation:landscape);
//$desktop: (min-width 992px), (max-device-width 1024px) (min-width 1024px) (orientation landscape);


// 992 matches with radix_layouts. Is this a good width? Not sure, but close enough to 980.
$wide: (min: 1140px);
//$wide: (width 1140px 1319px);
//$wide2: (min-width 1320px);
@mixin break-mobile {
  @include breakpoint($mobile) {
    @content
  }
}
@mixin break-narrow {
  @include breakpoint($narrow) {
    @content
  }
}
@mixin break-desktop {
  @include breakpoint($desktop) {
    @content;
  }
  .lt-ie9 & { @content ;}
  .no-js & { @content ;}
}
@mixin break-not-mobile {
  @include breakpoint($narrow) {
    @content;
  }
  @include breakpoint($desktop) {
    @content;
  }
  .lt-ie9 & { @content ;}
  .no-js & { @content ;}
}
@mixin break-wide {
  @include breakpoint($wide) {
    @content
  }
}

@mixin arrow_helper($arrowSize, $arrowColor, $margin, $side, $align) {
  @if $side == "top" {
    border-bottom-color: $arrowColor;
    top: -2 * $arrowSize;
  }
  @if $side == "bottom" {
    border-top-color: $arrowColor;
    bottom: -2 * $arrowSize;
  }
  @if $side == "left" {
    border-right-color: $arrowColor;
    left: -2 * $arrowSize;
  }
  @if $side == "right" {
    border-left-color: $arrowColor;
    right: -2 * $arrowSize;
  }
  @if $side == "right" or $side == "left" {
    @if $align == "center" {
      top: 50%;
      margin-top: -$arrowSize;
    }
    @else if $align == "top" {
      top: $margin;
    }
    @else if $align == "bottom" {
      bottom: $margin;
    }
  }
  @if $side == "bottom" or $side == "top" {
    @if $align == "center" {
      right: 50%;
      margin-right: -$arrowSize;
    }
    @else if $align == "left" {
      left: $margin;
    }
    @else if $align == "right" {
      right: $margin;
    }
  }
}

@mixin arrow($side: "right", $align: "center", $size: 20px, $color: #f6f6f6, $borderColor: "none", $borderSize: 3px) {
  $selector: "&:after, &:before";
  @if $borderColor == "none" {
    $selector: "&:after";
  }
  #{$selector} {
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    visibility: visible;
  }

  &:after {
    border-width: $size;
    @include arrow_helper($size, $color, $size, $side, $align);
  }

  @if $borderColor != "none" {
    &:before {
      border-width: $borderSize + $size;
      @include arrow_helper($size + $borderSize, $borderColor, $size - $borderSize, $side, $align);
    }
  }
}
////////////////////////////////////////

/* Homepage layout (including width radix overrides) */
.page-home {
  .pane-title {
    color: $wcm-red;
    font-weight: normal;
    line-height: 1.25;
//    @include break-not-mobile {
    @include breakpoint($sm) {
      min-height: 3em;
    }
    overflow: hidden;
    padding-right: 10px;
    margin: 0 0 .75em 0;

    a {
      &:link, &:visited {
        color: inherit;
      }
      /*&:hover, &:active {
        // color: $color-blue;
      }*/
    }
  }
}

.mcc-homepage {
    @include breakpoint($sm) {
        max-width: 980px;
    }
    @include breakpoint($lg) {
        max-width: 1180px;
    }
    margin: 0 auto;
    padding: 0 5px;
  .row {
    > .panel-panel {
//      @include box-sizing(border-box);
    }
//    @include break-narrow {
    @include breakpoint($xs) {
      padding: 0 15px;
      > .panel-panel {
        padding: 0;
      }
    }
//    @include breakpoint($md) {
    @include breakpoint($sm) {
      padding: 0 15px;
      > .panel-panel {
        padding: 0;
      }
    }
  }
  // Feature Carousel (.row1col1)
  .feature-carousel {
    margin-bottom: 0;
//    @include break-not-mobile {
    @include breakpoint($sm) {
      width: 59.76%;
      float: left;
    }
  }
  // Feature Grid (.row1col2)
  .feature-grid {
    overflow: hidden;
//    @include break-not-mobile {
    @include breakpoint($sm) {
      width: 40.23%;
      float: left;
      > .panel-panel-inner {
//        padding-right: 1px;
      }
    }
//    @include break-wide {
    @include breakpoint($lg) {
//      max-height: 440px;
    }
  }

  .row {
    margin-bottom: 5px;
//    @include break-not-mobile {
    @include breakpoint($sm) {
      margin-bottom: 1em;
    }
  }
  .row2 {
//    @include breakpoint($md) {
    @include breakpoint($md) {
      @include flex-columns;
    }
  }
  // News
  .row2col1 {
    margin-bottom: 5px;
      width: 100%;
    @include breakpoint($sm) {
      margin-bottom: 0px;
//      width: 100%;
    }
//    @include breakpoint($md) {
    @include breakpoint($md) {
      margin-bottom: 0;
      @include flex-columns;
      width: 59.76%;
      float: left;
    }
    .panel-panel-inner {
      background: $wcm-bg-gray;
      padding: 10px;
      position: relative;
//      @include break-narrow {
      @include breakpoint($xs) {
        padding: 15px;
      }
//      @include breakpoint($md) {
      @include breakpoint($sm) {
        padding: 20px 15px 30px 30px;
        @include flex-columns;
      }
    }
  }
  // Funding opportunities
  .row2col2 {
    display: none;
//    @include break-narrow {
    @include breakpoint($sm) {
      display: block;
      width: 33%;
    }
//    @include breakpoint($md) {
    @include breakpoint($md) {
      display: block;
      @include flex-columns;
      width: 23.33%;
      float: left;
    }
    .panel-panel-inner {
      padding: 10px;
      position: relative;
//      @include break-narrow {
      @include breakpoint($xs) {
        padding: 20px;
      }
//      @include breakpoint($md) {
      @include breakpoint($sm) {
        padding: 20px 20px 25px 30px;
        @include flex-columns;
      }
    }
  }
  // Calendar
  .row2col3 {
    position: relative;
//    @include break-narrow {
    @include breakpoint($sm) {
      width: 67%;
    }
//    @include breakpoint($md) {
    @include breakpoint($md) {
      @include flex-columns;
      width: 40.13%; // 40.23%;
      min-height: 470px;
      float: left;
    }
//    @include break-wide {
    @include breakpoint($lg) {
      min-height: 515px;
    }
    .panel-panel-inner {
//      @include breakpoint($md) {
      @include breakpoint($sm) {
        @include flex-columns;
      }
    }
  }
}

/* Sidebar */

.information-column {
    h3.pane-title {
        color: $wcm-dark-gray;
        font-size: 18px;
    }
}
.information-sidebar {
  h2.pane-title, h3.pane-title {
//    color: $wcm-black;
//    margin: 0 auto .5em 0;
//    margin-bottom: 1em;
//    text-transform: uppercase;
//    font-size: 20px;
//    font-weight: bold;
  }
  h4 {
//    color: $wcm-black;
//    // font-family: $frutiger;
//    margin: 1em auto .5em 0;
//    text-transform: uppercase;
//    font-size: 16px; //, 20px, 18px);
//    font-weight: bold;
  }
}
.information-sidebar, .information-column {
  .field-name-field-external-links,
  .field-name-field-quick-links-links,
  .pane-bundle-quick-links .fieldable-panels-pane,
  .panel-pane .pane-content, .panel-pane {

    a {
      /*display: block;
      padding-right: 1.25em;*/
      font-size: 13px;
      &:not(.linked-photo):not(.external-link) {
        @include icon-after('\e802');
      }
//      // font-family: $frutiger;
      &:link, &:visited {
        // color: $color-blue;
      }
      &:hover, &:active {
//        color: $wcm-dark-gray;
      }
    }

    > .item-list ul, > .field-items {
//      font-size: 1px;
//      line-height: 1;
      li, .field-item {
        font-size: 13px;
        list-style: none;
        margin: 0 0 .5em 0;
        display: block;
        a {
//          &:not(.external-link) {
//            @include icon-after('\e802');
//          }
          /*font-size: inherit;
          display: inline-block;
          padding-right: 1.25em;
          box-sizing: content-box;
          position: relative;*/
          //@include icon-after('\e802'); // icon-open-arrow-right
          /*&:after {
            position: absolute;
            top: 0px;
            right: -5px;
            font-size: 12px;
            padding-top: 1px;
          }*/
        }
      }
    }
  }

  .panel-pane .pane-content, .fieldable-panels-pane {
//    @include padding-trailer(1.5);
      padding-bottom: 1em;
//    padding-right: .5em;
  }
}

.information-sidebar .pane-bundle-photo-quick-links, .information-column .pane-bundle-photo-quick-links {
  padding-right: 0;
  .panel-pane .pane-content {
      a.linked-photo {
        display: block;
        margin-bottom: .75em;
        &:link, &:visited {
          text-decoration: none;
          color: $wcm-white;
        }
        &:hover, &:active {
          text-decoration: none;
          color: $wcm-white;
        }
        max-width: 308px; // matches sidebar_photo_links image-style
        overflow: hidden;
        position: relative;
        &:after {
          content: '';
          display: block;
          position: absolute;
          z-index: 1;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: rgba(black, .3);
          background: -webkit-linear-gradient(bottom, #000, rgba(0,0,0,0) 59%);
          background: -moz-linear-gradient(bottom, #000, rgba(0,0,0,0) 59%);
          background: -o-linear-gradient(bottom, #000, rgba(0,0,0,0) 59%);
          background: -ms-linear-gradient(bottom, #000, rgba(0,0,0,0) 59%);
          background: linear-gradient(to top, #000, rgba(0,0,0,0) 59%);
        }
      }
    }
  a.linked-photo {
    display: block;
    margin-bottom: .75em;
    padding: 0;
    font-family: inherit;
    font-size: 20px;
    &:link, &:visited {
      text-decoration: none;
      color: $wcm-white;
    }
    &:hover, &:active {
      text-decoration: none;
      color: $wcm-white;
    }
    max-width: 308px; // matches sidebar_photo_links image-style
    overflow: hidden;
    position: relative;
    &:after {
      content: '';
      display: block;
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(black, .3);
      background: -webkit-linear-gradient(bottom, #000, rgba(0,0,0,0) 59%);
      background: -moz-linear-gradient(bottom, #000, rgba(0,0,0,0) 59%);
      background: -o-linear-gradient(bottom, #000, rgba(0,0,0,0) 59%);
      background: -ms-linear-gradient(bottom, #000, rgba(0,0,0,0) 59%);
      background: linear-gradient(to top, #000, rgba(0,0,0,0) 59%);
    }
  }
  .link-image {
    z-index: 0;
    img {
//      @include transition(transform .5s);
      -webkit-transition: -webkit-transform .5s;
      -moz-transition: -moz-transform .5s;
      -ms-transition: -ms-transform .5s;
      -o-transition: -o-transform .5s;
      transition: transform .5s;

      -webkit-backface-visibility: hidden;
      -webkit-transform: translateZ(0) scale(1.0, 1.0);
    }
  }
  a:hover, a:active {
    .link-image {
      img {
//        @include transform(scale(1.2));
          -webkit-transform: scale(1.2);
          transform: scale(1.2);
      }
    }
  }
  .link-title {
    position: absolute;
    z-index: 2;
    bottom: 0px;
    padding: 0 1em 1em 1em;
    font-size: 16px;
    line-height: 22px;
    -webkit-backface-visibility: hidden;
    -webkit-transform: translateZ(0) scale(1.0, 1.0);
  }
}

.pane-mcc-programs-program-members {
  &.panel-pane .pane-content .item-list ul li a {
    // Get rid of the arrow.
    &:after {
      display: none;
    }
  }
  h4.program-title {
//    @include leader(1);
  }
}

/* Misc Radix layout override */
/* For Visiting Us page: */
.radix-layouts-secondcolumn1 {
  @include breakpoint($md) {
    padding-right: 30px;
  }
}
.radix-layouts-secondcolumn2 {
  @include breakpoint($md) {
    padding-left: 30px;
  }
}

/** MCC Layouts (mcc_layouts) custom layouts **/
// Applies to sidebar in mcc_sidebar and mcc_panel_sidebar
//.mcc-layouts {
.panel-hero-3col {
  .information-sidebar, .information-column {
    @include breakpoint($sm) {
//      padding-left: 0px;
//      margin-right: -1px; // this seems to be necessary for sticky-kit.js on Firefox.
//      .panel-panel-inner {
      .panels-ipe-region {
        margin: 1.8em 0;
        padding: 0 0 0 1.5em;
        border-left: 1px solid $wcm-border-gray;
      }
    }
  }
}
// Only mcc_sidebar layout .. slightly different width than radix gives.
.mcc-layouts-sidebar {
  .mcc-layouts-main-content {
    @include breakpoint($sm) {
//      width: 78.71%;
    }
    .panel-panel-inner {
      padding: 0 1em 0 0;
    }
  }
  .mcc-layouts-sidebar-sidebar {
    @include breakpoint($sm) {
//      width: 21%;
//      &.sticky {
//        width: 20.9%; // this is better for sticky-kit.js than 21.29%;
//      }
    }
  }
}

/* Home Features - Carousel & Grid */
.view-content-features-carousel {

  padding-right: 1px;
  .views-row + .views-row {
    display: none;
  }
  .bx-viewport .views-row + .views-row {
    display: block;
  }

  .views-row {
    background: $wcm-bg-gray;
  }
  .views-row .node {
    position: relative;

    .text {
      position: absolute;
      z-index: 5;
      bottom: 0;
      right: 0;
      color: $wcm-white;
      padding: 0 20px 20px 0;
    }

    .image {
      position: relative;
      z-index: 0;

      &:after {
        content: '';
        display: block;
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(black, .3);
        background: -webkit-linear-gradient(bottom, #000, rgba(0,0,0,.06) 45%, rgba(0,0,0,0) 48%);
        background: -moz-linear-gradient(bottom, #000, rgba(0,0,0,.06) 45%, rgba(0,0,0,0) 48%);
        background: -o-linear-gradient(bottom, #000, rgba(0,0,0,.06) 45%, rgba(0,0,0,0) 48%);
        background: -ms-linear-gradient(bottom, #000, rgba(0,0,0,.06) 45%, rgba(0,0,0,0) 48%);
        background: linear-gradient(to top, #000, rgba(0,0,0,.06) 45%, rgba(0,0,0,0) 48%);
      }
    }
  }

  position: relative;
  .bx-controls-direction {
    a {
      display: none;
      opacity: 0;
      @include transition(opacity 250ms);
      @include breakpoint($sm) {
        display: block;
      }
      position: absolute;
      top: 50%;
      margin-top: -20px;
      min-width: 40px;
      min-height: 40px;
      color: $wcm-white;
      font-size: 40px;
      &:link, &:visited {
        color: $wcm-white;
      }
      &:hover, &:active {
        color: $wcm-red;
      }
      span {
        display: none;
      }
//      text-indent: -999px;
      &.bx-prev {
        left: 5px;
          .icon-circle-arrow-left {
              @include icon-after('\e810');
          }
      }
      &.bx-next {
        right: 5px;

          .icon-circle-arrow-right {
              @include icon-after('\e802');
          }
      }
    }
  }
  &:hover {
    .bx-controls-direction a {
      opacity: 1;
    }
  }
  .bx-pager {
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
    text-align: center;
  }
  .bx-pager-item {
    display: inline;
    a {
      display: inline-block;
      width: 12px;
      height: 12px;
      border-radius: 6px;
      background: $wcm-white;
      overflow: hidden;
      text-indent: -999px;
      margin: 0 3px;
      &.active, &:hover {
        background: $wcm-red;
      }
    }
  }
}
.node.feature.carousel, .node.feature.grid {
  position: relative;
  img {
    display: block;
    width: 100%;
  }
  .text {
    position: absolute;
    z-index: 5;
    bottom: 0;
    right: 0;
    color: $wcm-white;
    padding: 0 20px 20px 20px;
  }

  .image {
    position: relative;
    z-index: 0;

    &:after {
      content: '';
      display: block;
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(black, .3);
      background: -webkit-linear-gradient(bottom, #000, rgba(0,0,0,.07) 63%, rgba(0,0,0,0) 68%);
      background: -moz-linear-gradient(bottom, #000, rgba(0,0,0,.07) 63%, rgba(0,0,0,0) 68%);
      background: -o-linear-gradient(bottom, #000, rgba(0,0,0,.07) 63%, rgba(0,0,0,0) 68%);
      background: -ms-linear-gradient(bottom, #000, rgba(0,0,0,.07) 63%, rgba(0,0,0,0) 68%);
      background: linear-gradient(to top, #000, rgba(0,0,0,.07) 63%, rgba(0,0,0,0) 68%);
    }
  }

  h4 {
    margin: 0;
    line-height: 1.1;
    color: inherit
  }
}
.node.feature.grid {
  a {
    display: block;
    overflow: hidden;
    -webkit-backface-visibility: hidden;
    img {
      @include transition(transform .5s);
    }
    &:hover {
      img {
        @include scale(1.2);
      }
    }
  }
}

.view-content-features-grid {
  .view-content {
//    @include clearfix();
    @include clearfix();
    margin: 5px -5px -5px 0;
//    @include break-not-mobile {
    @include breakpoint($sm) {
      margin-top: 0px;
      margin-right: -1px;
      margin-bottom: -1px;
    }
  }
  .views-row {
    width: 49.90%;
//    width: 50%;
    float: left;
    padding-right: 5px;
    padding-bottom: 5px;
    overflow: hidden;
//    @include box-sizing(border-box);
//    @include breakpoint($sm) {
//    @include breakpoint( ($sm and $md) ) {
//    @include breakpoint( ( min:  768px ) and ( max:  992px ) ) {
//    @include breakpoint($sm-only) {
    @include breakpoint($sm) {
      padding-right: 1px;
      padding-bottom: 1px;
    }
    /*@include breakpoint( (min: 600px) and (orientation: landscape) ) {
        padding-right: 1px;
      padding-bottom: 1px;
    }*/
  }
  .node.feature.grid {
    background: $wcm-bg-gray;
  }
}
.node.feature.carousel {
  .text {
    padding-bottom: 48px;
//    @include breakpoint($sm) {
    @include breakpoint($sm) {
      max-width: 50%;
      padding-left: 30px;
      padding-bottom: 80px;
    }
  }
  h4 {
      font-size: 20px;
    @include breakpoint($sm) {
        font-size: 30px;
      }
    margin-bottom: .5em;
  }
  div {
    line-height: 1.3;
      @include breakpoint($sm) {
          line-height: inherit;
      }
  }
  .field-name-field-feature-subtitle {
//    // font-family: $frutiger;
    font-size: 18px; //, 20px);
  }
}
.node.feature.grid {

  h4 {
      font-family: $wcm-regular;
//    font-size: 22px;
  }
  .text {
    left: 0;
  }
}

/* Home See More links */
// @todo Might apply everywhere?
.mcc-homepage {
  a.more-link {
    font-size: 12px;

    i {
      font-size: .8em;
      padding-left: .2em;
    }
  }

  h2, h3 {
    a {
      /*&:link, &:visited {
        color: $color-blue;
      }
      &:hover, &:active {
        color: $wcm-dark-gray;
      }*/
    }
  }
}
/* Home News */
.view-content-home-news {
  &.contextual-links-region {
    position: static;
  }
  .view-footer a.more-link {
    position: absolute;
    right: 20px;
    bottom: 10px;
  }

  .views-row-last {
    @include breakpoint($md) {
      display: none;
    }
    @include break-wide {
      display: block;
    }
//    @include break-wide2 {
//      display: block;
//    }
  }

//  .view-content {
//    @include display-flex();
//    @include flex-direction(row);
//    @include align-items(center);
//    @include justify-content(center);
//  }
//  .views-row {
//    @include display-flex();
//  }
}
/* News Item */
.home-news-item {
  .side.left {
    display: none;
//    @include break-narrow {
    @include breakpoint($sm) {
      display: block;
      margin: 0 1em 2em 0;
      float: left;
    }
//    @include breakpoint($md) {
    @include breakpoint($md) {
      display: block;
      margin: 0 1em 2em 0;
      float: left;
      width: 34%;
    }
    figcaption {
      display: none;
    }
  }
  .side.right {
//    @include font-size-mobile(14px, 18px);
      span.date-display-single {
          padding: 0;
          font-size: 13px;
      }
      h3 a:after {
          line-height: 2.5;
      }
    font-size: 14px;
    padding-bottom: 1.65em;
    @include breakpoint($sm) {
      width: 60%;
      margin-left: 20px;
      float: left;
    }
    @include breakpoint($md) {
//      @include font-size(14px, 18px, $break-desktop-font-size);
      font-size: 14px;
      margin-left: 0;
      width: 60%;
      float: right;
    }
    @include break-wide {
//      @include font-size(14px, 18px, $break-wide-font-size);
        font-size: 14px;
    }
//    @include break-wide2 {
//      @include font-size(14px, 18px, $break-wide2-font-size);
//    }
    * {
      margin: 0;
      font-size: inherit;
      line-height: inherit;
    }
    a.more-link {
      display: inline-block;
      text-align: left;
      font-size: 12px; //, 14px, 14px);
    }
  }

  .field-name-field-news-date {
    font-style: italic;
    color: $color-tan;
  }
}

/* Home Funding Opportunities */
.view-content-home-funding-opportunities {
  &.contextual-links-region {
    position: static;
  }
  .view-footer a.more-link {
    position: absolute;
    right: 20px;
    bottom: 10px;
  }
  .views-row-last {
    @include breakpoint($sm) {
      display: none;
    }
    @include breakpoint($lg) {
      display: block;
    }
  }
}
.home-funding-opportunity {
  font-size: 14px;
  &.node {
    div.panel-pane & {
      padding-bottom: 1.65em;
    }
  }
  @include breakpoint($md) {
    font-size: 14px; //, 18px, $break-desktop-font-size);
  }
  @include breakpoint($lg) {
    font-size: 14px; //, 18px, $break-wide-font-size);
  }
  * {
    margin: 0;
    font-size: inherit;
    line-height: inherit;
  }
  a.more-link {
    display: inline-block;
    text-align: left;
    font-size: 12px; //, 14px, 14px);
  }
}

/* Homepage Calendar */
.pane-home-calendar {
  @include clearfix();
  @include breakpoint($sm) {
    .pane-title {
      min-height: 0;
    }
  }
  .cell {
    width: 49.90%;
    float:left;
    min-height: 176px;
    position: relative;
    padding: 10px;
    @include box-sizing(border-box);
    @include breakpoint($sm) {
      min-height: 235px;
      padding-top: 20px;
      padding-left: 25px;
    }
    @include breakpoint($md) {
      .flexbox & {
        position: absolute;
        height: 50%;

        .cell-inner {
          position: relative;
          height: 100%;
          padding-bottom: 1.5em;
        }
      }
    }
    @include break-wide {
      min-height: 257px;
    }
  }

  .cell-one {
    @include arrow($color: $wcm-bg-gray, $side: right, $size: 10px);
    @include breakpoint($sm) {
      @include arrow($color: $wcm-bg-gray, $side: right, $size: 17px);
    }
    background: $wcm-bg-gray;
    padding-right: 20px;
    .flexbox & {
      top: 0;
      left: 0;
    }
    a.more-link {
      position: absolute;
      bottom: 5px;
      right: 5px;
      @include breakpoint($sm) {
        bottom: 0;
        right: 0;
      }
    }
  }
  .cell-two {
    .flexbox & {
      top: 0;
      right: 0;
    }
  }
  .cell-four {
    @include arrow($color: $wcm-bg-gray, $side: left, $size: 10px);
    @include breakpoint($sm) {
      @include arrow($color: $wcm-bg-gray, $side: left, $size: 17px);
    }
    background: $wcm-bg-gray;

    @include breakpoint($lg) {
      padding-top: 20px;
    }

    .flexbox & {
      bottom: 0;
      right: 0;
    }

    a.more-link {
      position: absolute;
      bottom: 5px;
      right: 5px;
      @include breakpoint($sm) {
        bottom: 0;
        right: 0;
      }
    }
  }

  .cell-selected-date {
    text-align: center;
    padding: 10px;
    .selected-date {
      @include vertical-center-container;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }
    .selected-date .content {
      @include vertical-center-content;
    }

    span.day {
      display: block;
      color: $wcm-red;
      @include font-size(10); //, 110px);
      line-height: 10rem;
    }
    span.month {
      display: block;
      @include font-size(2); //, 20px);
    }

    .flexbox & {
      bottom: 0;
      left: 0;
    }
  }

  .event h4 {
    font-size: 12px;
    @include breakpoint($sm) {
      font-size: 14px;
    }
    font-weight: normal;
    margin: 0 auto 1em auto;
    a {
      display: block;

        &:after {
            line-height: 1.5;
        }
    }
  }

}

#calendar-events-data {
  @include element-invisible;
}

// clndr.js styles
#home-calendar .clndr {
  $clndr-border: none; //  1px solid #000;
  margin: 0px 5px;
  line-height: 1em;
  font-size: 12px;
  @include breakpoint($sm) {
    font-size: 14px;
    margin: 4px auto 4px auto;
  }
  font-weight: bold;

  .clndr-controls {
    @include clearfix();
    width: 100%;
    position: relative;
    margin-bottom: 10px;

    .month {
      text-align: center;
      color: $wcm-red;
      @include breakpoint($sm) {
        font-size: 12px; //, 1.8);//, 14px);
      }
    }

    .clndr-control-button {
      display: block;
      overflow: visible;
      position: absolute;
      height: 20px;
      width: 10px;
      top: 0;
      @include user-select(none);
      cursor: pointer;
      &:hover {
//        background: #ddd;
      }
      &.inactive {
        opacity: 0.5;
        &:hover {
          background: none;
          cursor: default;
        }
      }

      span {
        display: none;
      }

      &.clndr-previous-button {
        left: 10px;
        @include arrow($size: 7px, $color: $wcm-black, $side: left, $align: right);
        @include breakpoint($sm) {
          @include arrow($size: 10px, $color: $wcm-black, $side: left, $align: right);
        }
        &:after {
          left: -10px;
        }
      }
      &.clndr-next-button {
        right: 10px;
        @include arrow($size: 7px, $color: $wcm-black, $side: right, $align: right);
        @include breakpoint($sm) {
          @include arrow($size: 10px, $color: $wcm-black, $side: right, $align: right);
        }
        &:after {
          right: -10px;
        }
      }
    }
  }

  .clndr-table {
    table-layout: fixed;
    width: 100%;
    margin: 0;

    tr {
      $box-size: 19px;
      height: $box-size;
      @include break-narrow {
        padding: 0;
        height: $box-size * 1.21052631578947; // 23px
      }
      @include breakpoint($md) {
        padding: 0;
        height: $box-size * 1.26315789473684; // 24px
      }
      @include breakpoint($lg) {
        padding: 0;
        height: $box-size * 1.42105263157895; // 27px
      }
    }

    .header-days {
      .header-day {
        vertical-align: middle;
        text-align: center;
        border-left: $clndr-border;
        border-top: $clndr-border;

        &:last-child {
          border-right: $clndr-border;
        }
      }
    }

    tbody {
      border-top: none;
    }
    tr {
      td {
        vertical-align: middle;
        padding: 0;
      }

      .day {
        border-left: $clndr-border;
        border-top: $clndr-border;
        width: 100%;
        height: inherit;
        cursor: default;
        @include user-select(none);

        .day-contents {
//          @include box-sizing(border-box);
          text-align: center;
          position: relative;
        }
        .marker {
          display: none;
          position: absolute;
          background: $wcm-red;
          bottom: -5px; // 1px;
          left: 50%;
          width: 4px;
          height: 4px;
          border-radius: 2px;
          margin-left: -2px;
          @include breakpoint($lg) {
            bottom: -7px; // 1px;
            width: 6px;
            height: 6px;
            border-radius: 3px;
            margin-left: -3px;
          }
        }

        &:hover {
//          background: #eee;
        }
        &.event {
          &:hover {
            background: $wcm-border-gray;
            .marker {
//              display: none;
            }
          }
          cursor: pointer;
          .marker {
            display: block;
            background: $wcm-red;
          }
        }
        &.past.event {
          .marker {
            background: $wcm-border-gray;
          }
        }

        &.selected {
          background: $wcm-red;
          color: $wcm-white;
          &:hover {
            background: $wcm-red;
          }
          &.event {
            .marker {
              display: none;
            }
          }
        }

        &.inactive {
          background: transparent; //#ddd;
        }

        &:last-child {
          border-right: $clndr-border;
        }

      }

      .empty, .adjacent-month {
        border-left: $clndr-border;
        border-top: $clndr-border;
        width: 100%;
        height: inherit;
        background: transparent; // #eee;

        &:last-child {
          border-right: $clndr-border;
        }
      }

      &:last-child {
        .day {
          border-bottom: $clndr-border;
        }

        .empty {
          border-bottom: $clndr-border;
        }
      }
    }
  }
}

/* Interactive Calendar (Calendar page) */
.pane-interactive-calendar {
  h3#calendar-title {
    text-transform: uppercase;
    font-size: 28px;
    margin: 2em auto;
    @include breakpoint($md) {
       margin: 1.8em auto;
       font-size: 34px;
    }
  }
}
#calendar-events {
  margin: 1em auto;
}
.events-list.full-page {
  .event {
    .image {
      display: none;
      margin-bottom: 1.5em;
      @include breakpoint($md) {
        display: block;
      }
    }
    .header {
      @include clearfix();
    }
    h3 {
      margin: 0 0 0 2.35em;
      padding: 0 0 .15em 1em;
      border-left: 1px solid $wcm-border-gray;
      color: $wcm-red;
      font-family: $wcm-regular;
      font-weight: normal;
      font-size: 30px; //, 35px);
      a {
        display: block;
        color: inherit;
        &:link, &:visited {
          color: inherit;
          text-decoration: none;
        }
        &:hover, &:active {
//          // color: $color-blue;
        }
      }
    }

    .description {
      margin: 1.5em auto;
    }

    .time, .location {
      line-height: 1.25;
    }
  }

  .event {
    margin-bottom: 1em;
    @include breakpoint($sm) {
      margin-bottom: 3em;
      @include clearfix();
      .side {
        float: left;
//        @include box-sizing(border-box);

        &.left {
          width: 17%;
          min-height: 1px;
          padding: 0 .5em 0 0;
        }
        &.right {
          padding: 0 7em 0 2.8em;
          width: 83%;
        }
      }
    }
    .side.right {
      padding-bottom: 1em;
      @include breakpoint($sm) {
        padding-bottom: 3em;
      }
      border-bottom: 1px solid $wcm-border-gray;
    }
    &.last {
      .side.right {
        border-bottom: none;
      }
    }
  }
}

.dk-initialized {
    label {
        margin-bottom: 0;
    }
}

.dk-select {
    &.form-control {
        height: 0;
        max-width: none;
        border: none;
        padding: 0;
    }
}

#calendar-controls {
  margin-top: 2em;
  a.next-month-link {
    @include element-invisible;
    // This link is in the template for search engines to be able to move to next month without Javascript
  }
  .year {
    font-size: 18px;
  }
  .month-selector-container {
    margin-bottom: 10px;
    &.dk-initialized {
//      z-index: 20;
      i {
        top: auto;
        bottom: .75em;
        right: -10px;
        @include icon-after('\e80f');
        font-size: 20px;
      }
    }
    .dk-selected {
      color: $wcm-red;
      font-size: 36px;
      margin: 0 0 5px 0;
      padding: 0 .5em 0 0;
    }
    .dk-select-options {
      border-top: 1px solid $wcm-black;
    }
  }
  .clndr-grid {
    padding: 0 11.11%;
    margin-bottom: .75em;
    @include breakpoint($md) {
      padding: 0 80px;
    }
    @include breakpoint($lg) {
      padding: 0 70px;
    }
    position: relative;

    .bx-controls {
      a {
        position: absolute;
        display: block;
        top: 0;
        bottom: 10px;
        width: 11.11%;
        @include breakpoint($md) {
          width: 80px;
        }
        @include breakpoint($lg) {
          width: 70px;
        }
        height: auto;
        background: $wcm-med-gray;
        &:hover {
          background: $wcm-red;
        }
        text-indent: -999px;
        overflow: hidden;
        &.bx-prev {
          left: 0;
          @include arrow($size: 10px, $color: $wcm-white, $side: left);
          &:after {
            left: 50%;
            margin-left: -15px;
          }
        }
        &.bx-next {
          right: 0;
          @include arrow($size: 10px, $color: $wcm-white, $side: right);
          &:after {
            right: 50%;
            margin-right: -15px;
          }
          border-left: 1px solid $wcm-bg-gray;
        }
      }
    }
  }
  .calendar-filters {
    .filter-viewby .dk-option[data-value="all"] {
      display: none;
    }
  }
  .days {
    @include clearfix();
  }
  .day {
    float: left;
    text-align: center;
    // font-family: $frutiger;
    width: 80px;

    .inner {
      background: $wcm-bg-gray;
      border-left: 1px solid $wcm-bg-gray;
//      border-right: 1px solid $wcm-bg-gray;
      margin-bottom: 10px;
      padding: 10px 0;
      position: relative;
    }

    &.today {
      .inner {
        color: $wcm-red;
        background: $wcm-bg-gray;
        font-weight: 900; // Frutiger Black
      }
    }
    &.event {
      cursor: pointer;
      .inner {
        background: $wcm-red;
        color: $wcm-white;
        @include arrow($side: 'bottom', $align: 'center', $size: 10px, $color: $wcm-red);
      }
      &:hover {
        .inner {
          background: $wcm-med-gray;
          @include arrow($side: 'bottom', $align: 'center', $size: 10px, $color: $wcm-med-gray);
        }
      }
      &.selected {
        .inner {
          background: $wcm-red;
          color: $wcm-white;
          @include arrow($side: 'bottom', $align: 'center', $size: 10px, $color: $wcm-red);
        }
      }
    }
    &.event.past {
      .inner {
        background: $wcm-med-gray;
        color: $wcm-white;
        @include arrow($side: 'bottom', $align: 'center', $size: 10px, $color: $wcm-med-gray);
      }
      &:hover {
        .inner {
          background: $wcm-dark-gray;
          @include arrow($side: 'bottom', $align: 'center', $size: 10px, $color: $wcm-dark-gray);
        }
      }
      &.selected {
        .inner {
          background: $wcm-dark-gray;
          @include arrow($side: 'bottom', $align: 'center', $size: 10px, $color: $wcm-dark-gray);
        }
      }
    }
    &.event.today {
      .inner {
        background: $wcm-red;
        color: $wcm-white;
        @include arrow($side: 'bottom', $align: 'center', $size: 10px, $color: $wcm-red);
      }
      &:hover {
        .inner {
          background: $wcm-med-gray;
          @include arrow($side: 'bottom', $align: 'center', $size: 10px, $color: $wcm-med-gray);
        }
      }
    }
    span {
      display: block;
    }
    .day-of-week {
      font-size: 14px;
      text-transform: uppercase;
    }
    .day-number {
        font-size: 14px;
    }
  }
}

/* Date styling */
/* Used by both Calendar page and News page */
.event-date {
  .year {
    display: none;
  }
  .month {
    display: block;
    text-transform: uppercase;
    border-bottom: 3px solid $wcm-red;
    @include font-size(1.8); //px, 29px);
  }
  .day {
    display: block;
    @include font-size(3.4);//px, 46.8px);
    line-height: 4rem;
  }
  float: left;
  text-align: center;
}

/* News */
span.date-display-single {
    font-family: $wcm-italic;
    padding: 20px 0;
    display: block;
}
.view-content-news {
  margin: 1.8em 0;
  h3 {
    margin: 0 0 1em 2.35em;
    padding: 0 0 .15em 1em;
    border-left: 1px solid $wcm-border-gray;
    font-family: $wcm-regular;
//    color: $wcm-red;
//    font-weight: normal;
    @include font-size(2.2); //, 26px);
    min-height: 3.5em;
    @include breakpoint($sm) {
      @include font-size(3);//, 35px);
      min-height: 2em;
    }
    a {
      display: block;
        &:after {
            content: '';
        }
//      color: inherit;
      &:link, &:visited {
//        color: inherit;
        text-decoration: none;
      }
      &:hover, &:active {
        // color: $color-blue;
      }
    }
  }

  .views-row {
    // margin: rhythm(1) 0;
    margin: 1.8em 0;
    .side.left {
      display: none;
    }
    @include breakpoint($sm) {
      @include clearfix();
      .side {
        float: left;
//        @include box-sizing(border-box);

        &.left {
          display: block;
          width: 17%;
          min-height: 1px;
          padding: 0 .5em 0 0;
        }
        &.right {
          padding: 0 0.9em 0 1.8em;
//          padding: 0 rhythm(.5) 0 rhythm(1);
          width: 83%;
        }
      }
    }
    .side.right {
      padding-bottom: 1em;
      @include breakpoint($sm) {
        padding-bottom: 3em;
      }
      border-bottom: 1px solid $wcm-border-gray;
    }
    &.views-row-last {
      .side.right {
        border-bottom: none;
      }
    }

    a.more-link {
      display: inline;
      white-space: nowrap;
    }
  }

}

/* Generic for Panelizer Full Content ovveride of nodes */
/* First applied to news_item nodes */
.page-node .node, .page-node .panel-display {

  .field-name-field-featured-image {
    float: left;
    max-width: 25%;
    margin: .25em 1.5em .25em 0;
    img {
      // get rid of poor panopoly stylings
      width: inherit;
      max-width: 100%;
      float: none;
      margin: 0;
    }
  }
  .pane-node-field-news-date, .field-name-field-news-date {
    font-style: italic;
    color: $wcm-dark-gray;
    @include breakpoint($sm) {
      margin-bottom: -(rhythm(1));
    }
  }

}

/* Views Responsive Grid for */
/* Research Programs, Shared Resources etc */
.mcc-content-grid {
  margin: 2em auto;
  @include breakpoint($md) {
//    margin-right: -2.9%;
  }
  .views-row {
    @include breakpoint($md) {
      @include flex-columns;
      margin: 0 -28px;
    }
    .css-test-colors & {
      background: green;
    }
    @include clearfix();
  }
  .views-responsive-grid.views-columns-2 {
    @include breakpoint($md) {
      margin-right: 85px;
    }
    .views-column {
      padding-bottom: 5px;
      margin-bottom: 30px;
      border-bottom: 1px solid $wcm-border-gray;
      @include breakpoint($lg) {
        margin-bottom: 3em;
        padding-bottom: 0;
        border-bottom: none;
        float: left;
        width: 50%;
//        border-left: 1px solid $wcm-bg-gray;
        .css-test-colors & {
          background: purple;
          border-left: 1px solid yellow;
        }
        &.views-column-first {
          border-left: none;
        }
        > article, > div {
          padding-left: 28px;
          padding-right: 24px;
        }
      }
    }
  }
  .views-responsive-grid.views-columns-3 {
    .views-column {
      padding-bottom: 15px;
      margin-bottom: 30px;
      border-bottom: 1px solid $wcm-border-gray;
      @include breakpoint($lg) {
        margin-bottom: 3em;
        padding-bottom: 0;
        border-bottom: none;
        float: left;
        width: 33.33%;
        border-left: 1px solid $wcm-border-gray;
        .css-test-colors & {
          background: purple;
          border-left: 1px solid yellow;
        }
        &.views-column-first {
          border-left: none;
        }
        > article, > div {
          padding-left: 28px;
          padding-right: 24px;
        }
      }
    }
  }

  h2 {
    @include font-size(28px, 36px);
    // margin: rhythm(.4) 0;
  }

  .field-type-image {
//    margin: rhythm(.6) 0;
    img {
      display: block;
    }
  }

  .field-name-body, a.more-link {

      .icon-open-arrow-right {
        @include icon-after('\e802');
          font-size: 80%;
          padding-left: 5px;
      }
    // margin: rhythm(.6) 0;
    /*@include font-size(1.6);//, 22px); //, $base-font-size);
    @include breakpoint($md) {
      @include font-size(1.6); //, 22px); //$break-desktop-font-size);
    }
    @include break-wide {
      @include font-size(1.6); //, 22px); // $break-wide-font-size);
    }*/
//    @include break-wide2 {
//      @include font-size(16px, 22px, $break-wide2-font-size);
//    }
    // Same size at all breakpoints. Reconsider?
  }

}

/* External Links (field_external_links) */
/* Shared Resources, etc */
.field-name-field-external-links {
  .mcc-content-grid & {
    // margin: rhythm(.6) 0;
    font-size: 16px; //, 22px) ;//, $base-font-size);
    @include breakpoint($md) {
      font-size: 16px; //, 26px); //, $break-desktop-font-size);
    }
    @include breakpoint($lg) {
      font-size: 16px; //, 26px); //, $break-wide-font-size);
    }
//    @include break-wide2 {
//      @include font-size(16px, 26px, $break-wide2-font-size);
//    }

    .field-item {
      a {
//        // font-family: $frutiger;
//        @include icon-after('\e806');// icon-open-arrow-right
        &:after {
          font-size: .8em;
        }
      }
    }
  }
}

/** Clinical Trials **/
/* (views within views, within views) */
.view-content-clinical-trials {
  h2 {
//    font-size: 24px; //, 30px);
    text-transform: uppercase;
//    font-weight: bold;
     margin: 0.9em 0;
  }
  h3 {
//    font-size: 18px; //, 24px);
//    font-weight: bold;
    // margin: rhythm(.25) 0;
  }
  h4 {
//    @include font-size(18px, 24px);
    font-weight: normal;
    // margin: rhythm(.25) 0;
    // font-family: $frutiger;
  }

  .views-row.level-1 {
    margin-bottom: rhythm(1);
  }
  .views-responsive-grid{
    @include clearfix();
    @include breakpoint($sm) {
      > .views-column {
        float: left;
        padding-right: 1.5em;
      }
      &.views-columns-2 {
        > .views-column {
          width: 50%;
        }
      }
      &.views-columns-3 {
        > .views-column {
          width: 33%;
        }
      }
    }
  }
}

// WCM Clinical Trials
.pane-clinical-trials-panel-pane-1 {
  .view-clinical-trials{
    padding-top: 20px !important;
  }

  .views-row {
    padding: 15px 0 20px;
    border-bottom: 1px solid $wcm-border-gray;

    .trial-title {
			padding-bottom: 10px;
			font-size: 17px;
    }

    .views-field {
			padding-bottom: 2px;
			.views-label {
				font-weight: 600;
			}
			.field-content {
				display: inline;
			}
		}

		.views-field-field-trial-source-link {
			padding-top: 10px;
    }

    h3 {
      margin-bottom: 15px;
      text-transform: lowercase;

      a {
        text-transform: capitalize;
      }
    }
  }

  .views-field-field-trial-key-eligibility {
    font-size: 1.3rem;
    margin-bottom: 20px;
  }

  .form-item-field-trial-primary-investigator-value-selective {
    .form-select {
      width: 100%;
      max-width: 100%;

      @include breakpoint($md) {
        width: 300px;
      }
    }
  }

  .form-item-field-trial-category-target-id-selective {
    .form-select {
      width: 100%;
      max-width: 100%;

      @include breakpoint($md) {
        width: 300px;
      }
    }
  }

  // .views-exposed-form {
  //   margin-bottom: 40px;
  //   @include breakpoint($xs) {
  //     padding-bottom: 0px;
  //   }
  // }

  .views-submit-button {
    padding: 10px 0 20px 0 !important;
    width: 100%;

    .form-submit {
    margin-top: 0 !important;
    }

  }

  .btn-sml {
    background-color: $wcm-dark-orange;
    color: #fff;
    border-radius: 10px;
    height: 30px;
    line-height: 28px;
    padding-bottom: 0;
    padding-top: 0;
    font-size: 13px;
  }

}

/* General Views */
.view {
  label {
//    font-weight: normal;
  }
  @include clearfix();
}
/* Two Column Table View */
/* Funding Opporunities, Research Opportunities */
.view.two-column-list {
  margin: 1em auto;
//  border-top: 1px solid $wcm-bg-gray;
  @include breakpoint($md) {
    border-top: none;
    margin: 4em auto;
  }
  .node-teaser {
    a.teaser-link {
      &:link, &:visited {
        color: inherit;
      }
      &:hover, &:active {
        text-decoration: none;
      }
    }
  }

  .view-header {
    color: $wcm-red;
    font-size: 24px;
    display: none;
    @include breakpoint($sm) {
      display: block;
    }
  }
  .views-row a.teaser-link {
    display: block;
    @include breakpoint($sm) {
//      @include flex-columns();
    }
    @include clearfix();
  }
  .view-header, .views-row a.teaser-link {
    @include clearfix();
    padding: 1em .5em 1em .5em;
    border-bottom: 1px solid $wcm-border-gray;
    @include breakpoint($sm) {
      margin: 0;
      padding: 1em 0 1em .5em;
    }
  }
  .side {
    @include breakpoint($sm) {
      float: left;
      &.left {
        width: 29%;
        padding-right: 2em;
      }
      &.right {
        max-width: 71%;
        position: relative;
        padding-right: 4.5em; // aprox 70px at wide2
      }
    }

    .link-arrow {
//      display: none;
      padding-top: 1em;
       color: $wcm-dark-orange;

      @include breakpoint($sm) {
        padding-top: 0;
        span.mobile-text {
          display: none;
        }
        display: block;
        width: 40px;
        height: 36px;
        position: absolute;
        right: 0;
        top: 50%;
        margin-top: -18px;
        bottom: 0;
        @include font-size(38px, 38px);
        color: $wcm-dark-gray;
      }
    }
  }

  h3 {
//    @include font-size(20px);
    margin-top: 0;
  }

  .views-row a.teaser-link {
    @include transition(background 250ms);
    .side .link-arrow {
      @include transition(color 250ms);
    }
    @include breakpoint($md) {
      &:hover {
        background: $wcm-bg-gray;
        .side .link-arrow {
          color: lighten($wcm-dark-gray, 25%);
        }
      }
    }
  }
}

/* Three Column Table View */
/* Members Directory */
// @todo Combine with .view.two-column-list (above)
.view.view-column-list {
    margin: 1.8em auto;
//  border-top: 1px solid $wcm-bg-gray;
  @include breakpoint($md) {
    border-top: none;
    margin: 1.8em auto;
  }
  .node-teaser {
    a.teaser-link {
      &:link, &:visited {
        color: inherit;
      }
      &:hover, &:active {
        text-decoration: none;
      }
      display: block;
      @include clearfix();
    }
  }

  .view-header, .column-list-header {
    color: $wcm-red;
    // font-family: $frutiger;
    font-size: 24px;
    display: none;
    @include clearfix();
    margin-top: 1.8em;
    @include breakpoint($sm) {
      display: block;
    }
  }
  .views-row a.teaser-link {
    display: block;
  }
  .view-header, .column-list-header, .views-row, .view-empty {
    @include clearfix();
    padding: 1.5em 0px;
    border-bottom: 1px solid $wcm-bg-gray;
    @include breakpoint($sm) {
      margin: 0;
      padding: 1.5em 10px 1.5em 10px;
    }
  }
  .view-empty {
    h2 {
      margin: 0;
    }
  }

  .side {
    @include breakpoint($sm) {
      float: left;
      &.left {
        width: 29%;
        padding-right: 2em;
      }
      &.right {
        max-width: 71%;
        position: relative;
        padding-right: 40px;
      }
    }

    .link-arrow {
      display: none;
      @include breakpoint($sm) {
        display: block;
        width: 40px;
        height: 40px;
        position: absolute;
        right: 0;
        top: 50%;
        margin-top: -20px;
        bottom: 0;
        @include font-size(35px, 35px);
        color: lighten($wcm-dark-gray, 25%);
      }
    }
  }
  @include breakpoint($sm) {
    .column {
      float: left;
      min-height: 1px;
    }
    &.views-columns-3 {
      .column {
        width: 33%;
        padding-right: 1em;
      }
    }
  }

  h3 {
//    color: $color-tan;
    font-size: 20px;
    margin-top: 0;
  }

  .views-row {
    @include breakpoint($md) {
      &:hover {
        background: $wcm-bg-gray;

        .side .link-arrow {
          color: $wcm-dark-gray;
        }
      }
    }
  }
}
/* Pagination */
.view .item-list {
//  text-align: center;
  // Need to make ul.pager center itself.
  // @todo this could become problematic? YES IT DID!
}

/* Views exposed filters */
/* Radio buttons (Research Opportunities) */
.large-radio-buttons {
  .views-exposed-widget {
    @include breakpoint($sm) {
      > label {
        display: inline-block;
        margin-right: 1em;
        padding-left: 10px;
      }
      .views-widget {
        display: inline-block;
      }
    }
  }
    div.form-type-radio {
        display: inline;
        font-size: 75%;
        margin-right: 5px;
        @include breakpoint($sm) {
            display: inline;
            font-size: 100%;
            margin-right: 10px;
        }
        input[type=radio] {
            display: none;
        }
        label {
            display: inline-block;
            background: $wcm-bright-orange;
            min-width: 5em;
            text-align: center;
            @include transition(background 250ms);
            &:hover {
                background: $wcm-red;
            }
            color: $wcm-white;
            font-size: 16px;
            line-height: 2.5em;
            cursor: pointer;
            padding: 0 15px;
            border-radius: 20px;
        }
        input[type=radio][checked] + label {
            background-color: $wcm-red;
        }
    }
}

/** MCC Panels Layouts **/
.mcc-layouts-main-content {
  @include breakpoint($sm) {
  }
}

/** Panels Bottom **/
div.line-divider {
  position: absolute;
  left: 0;
  right: 0;
  height: 1px;
  border-top: 1px solid $wcm-bg-gray;
  border-bottom: none;
  margin: 0;
}
.mcc-layouts-bottom {
  min-height: 100px;
  // margin: rhythm(1) 0 0 0;
  .mcc-homepage & {
    margin: 0;
  }
}

/** Location Maps **/
.location-map {
  // margin: rhythm(1) 0;
}
/** Address Content **/
.address-wrap {
    text-align: center;
    margin: 1.8em 0;

    .name {
        font-size: 20px;
        line-height: 26px;
        color: $wcm-dark-gray;
        font-family: $wcm-regular;
        span {
            display: block;
        }
    }
    .affiliation {
        font-size: 14px;
    }

    .address {
      font-size:16px;
        color: $wcm-dark-gray;
    }
  &.color-cornell {
    .name, .affiliation {
      color: $wcm-red;
    }
  }
  &.size-large {
    .name {
      font-size: 34px; //, 38px);
    }
    .affiliation {
      font-size: 22px; //, 38px);
    }
    .address {
      font-size: 20px; //, 38px);
    }
  }
  &.style-alt {
    .name, .affiliation {
      color: $wcm-red;
    }
    .address {
      font-family: inherit;
    }
  }
  &.align-center {
    text-align: center;
  }
}
.address-map-wrap {
  display: table;
  .lt-ie9 & {
    display: block;
    position: relative;
    @include clearfix();
  }
  .mcc-homepage & {
    margin: 0;
  }
  .side {
//    @include breakpoint($sm) {
    @include breakpoint($sm) {
      display: table-cell;
      vertical-align: middle;
      &.left {
        width: 24%;
      }
      &.right {
        width: 76%;
      }
    }
    .lt-ie9 & {
      display: block;
      float: left;
      position: relative;
      &.left {
        min-height: 1px;
      }
      &.right {
        min-height: 1px;
      }
    }
  }
  .location-map {
    margin: 0;
    img {
      width: 100%;
    }
  }

  .address-wrap {
    @include breakpoint($sm) {
      padding: 0 1em;
      &:before {
        margin-right: 0;
      }
    }
  }
}

/** Ctools Modal - Inline Panels Editor, etc **/
div.ctools-modal-content {
//  font-family: $helvetica;
  font-size: 100%;
  line-height: 1.2;
}

/**
 * Dropdown & Select styling
 **/
 .views-attachment{
  .container-inline{
    padding: 0.5em 0.5em 0 0 !important;
   }
   .container-inline div, .container-inline label {
    float: left;
    width: 100%;
    margin: 0 0 5px 0;
    @include breakpoint($md) {
      width: 14% !important;
      margin-right: 1em;
    }
  }
 }

div#edit-role-wrapper,
div#edit-research-program-wrapper,
div#edit-field-member-physician-specialty-value-wrapper,
div#edit-name-wrapper{
  width: 100%;
  @include breakpoint($md) {
    width: 21%;
  }
}

div#edit-name-wrapper{
  label.infield{
    display: block !important;
  }
}

.view, #calendar-controls {
  .dk-dropdown label, .views-exposed-form label, .views-attachment label {
    @include font-size(1.2); //px, 46px);
    line-height: 2.5em;
    font-weight: normal;
  }
  .dk-dropdown {
    min-width: 6em;
    float: left;
    margin-right: 1em;
    &.filter-type {
      min-width: 9em;
    }
  }
}
.views-exposed-form .views-exposed-widget {
  padding: 0 1em 0 0;
}

#edit-location-wrapper,
#edit-category-wrapper{
  width: 100%;
  @include breakpoint($md) {
    width: auto;
  }
}

select, ul.views-summary {
  .js .ctools-modal-content & {
    visibility: visible;
  }
}
.dk-initialized {
  float:left;
  z-index: 5;
  position: relative;
  padding: .5em 0 0 0;
  margin-right: 1.5em;
  label {
    border-bottom: 1px solid $wcm-black;
    text-align: left;
    display: block;
    padding-right: 25px;
    position: relative;
  }
  i {
    font-size: 14px;
    vertical-align: middle;
    position: absolute;
    right: 0px;
    bottom: .5em; // no idea why .. but it works
    line-height: inherit;
    @include icon-after('\e80f');
//    top: 0;
  }
  a.trigger, div.dk-selected {
    position: absolute;
    visibility: visible !important;
    .view-content-member-directory & {
      display: none;
    }
    @include breakpoint($sm) {
      display: block;
    }
  }
  div.dk-selected {
    .view-content-member-directory & {
      display: none;
      // no room on this view because filters are wrapped to 2 lines.
    }
    .view-content-member-directory &, .pane-interactive-calendar & {
      @include font-size(1.2); //, 18px);
      margin: 3px 10px;
      @include breakpoint($sm) {
        display: block;
        margin: 10px;
      }
      padding: .5em;
      visibility: visible;
      color: $wcm-red;
    }
  }
  &.no-label {
    a.trigger, div.dk-selected {
      position: static;
      visibility: visible;
    }
  }
  &.item-list ul, .dropcontainer ul, .dk-select ul, .dk-select-options.dk-select-options-highlight {
    visibility: visible;
    margin: 0;
    display: none;
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    overflow: auto !important;
    max-height: 500px !important;
    @include clearfix();
    border: 1px solid $wcm-border-gray;
    border-top: none;
    padding: 10px;
    background: $wcm-white;
    @include breakpoint($xs) {
      overflow: scroll !important;
      max-height: revert !important;
    }
  }

  .dropcontainer ul, .dk-select ul {
    li {
      margin: 0;
      padding: 0;
      list-style: none;
      list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7); // fix IE 10&11 bug (transparent gif)
      @include font-size(1.2); //, 18px);
      display: block;
      padding: .5em;
      color: $wcm-black;
      cursor: pointer;
      &:hover, &.dk-option-selected {
        color: $wcm-red;
      }
    }
  }

  &.dk-opened {
    z-index: 999;
    ul.dk-select-options, ul.views-summary {
      display: block;
      height: auto;
    }
  }

  .dk-select + select {
    display: none;
  }
}

.desktop-search-select.dk-initialized {
  margin: 0;
  padding: 0 1.75em 0 1em;
  line-height: 55px;
  // font-family: $frutiger;
  div.dk-selected {
    position: static;
    visibility: visible;
    padding: 0 1em 0 0;
  }
  i {
    right: 2.5em;
    bottom: 0; // .1em;
  }
  ul.dk-select-options {
    padding: .5em .5em .5em .5em;
    z-index: 10;
    margin-top: -2px;
    border: 2px solid;
    margin-left: -2px;
    margin-right: -2px;
    border-top: none;
  }
}

/* How You Can Help Button */
div.large-button-wrap {
  text-align: center;
}
a.large-button {
  display: inline-block;
  // margin: rhythm(1) 0 rhythm(2) 0;
  font-size: 20px;
  text-align: center;
  padding: 0 50px;
  background: $wcm-bright-orange;
  min-width: 100px;
  line-height: 3.05em; // 73px at wide2
  border-radius: 3px;
  @include transition(all 250ms);
  &:link, &:visited {
    color: $wcm-white;
  }
  &:hover, &:active {
    text-decoration: none;
    background: $wcm-red;
  }
}


/** Member Directory styling **/
// .view-content-member-directory {
//   position: relative;

//   .views-submit-button {
//     padding: 0;
//     margin: 0;
//   }

//   .filters-and-glossary {
//     margin-bottom: 0.9em;
//     @include breakpoint($sm) {
//       margin-bottom: 1.8em;
//     }
//   }
//   .views-exposed-widgets {
//     @include breakpoint($sm) {
//       float: left;
//       padding-left: 10px;
//     }
//   }
  // // Filter By Program
  // #edit-research-program-wrapper {
  //   width: 41%;
  //   margin-right: 0;
  //   @include breakpoint($sm) {
  //     width: auto;
  //     min-width: 9.5em; // around 190px at wide2
  //     margin-right: 1.5em;
  //   }
  // }
  // // Filter By Role /
  // #edit-role-wrapper {
  //   width: 35%;
  //   margin-left: 5%;
  //   margin-right: 9%;
  //   @include breakpoint($sm) {
  //     margin-left: 0;
  //     margin-right: 0;
  //     min-width: 8em;
  //     width: auto;
  //   }
  // }
  // Filter by Name (first letter)
  // .member-directory-attachment {
  //   @include breakpoint($sm) {
  //     float: left;
  //   }
  //   .dk-initialized {
  //     padding: 0;
  //     position: relative;
  //     cursor: pointer;
  //     width: 35%;
  //     margin-left: 5%;
  //     margin-right: 9%;
  //     @include breakpoint($sm) {
  //       width: auto;
  //       min-width: 7em; // around 140px at wide2
  //       margin-left: 0;
  //       margin-right: 1em;
  //     }
  //     li {
  //       list-style: none;
  //       list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7); // fix IE 10&11 bug (transparent gif)
  //       margin: 0;
  //       float: left;
  //       width: 33%;
  //       @include font-size(1.2); //px, 16px);
  //       text-align: center;
  //       &.filter-reset {
  //         width: 100%;
  //         text-align: left;
  //       }

  //       a {
  //         display: block;
  //         padding: 10px;
  //         &:link, &:visited {
  //           color: $wcm-black;
  //         }
  //         &:hover, &:active {
  //           color: $wcm-red;
  //         }
  //       }
  //       &.dk-option-selected {
  //         a {
  //           &:link, &:visited {
  //             color: $wcm-red;
  //           }
  //         }
  //       }
  //     }
  //   }

  // }

//   .views-row {
//     // font-family: $frutiger;
// //    @include font-size-mobile(14px, 20px);
//     font-size: 14px; //, 22px);
//     color: $wcm-dark-gray;

//     h3 {
//       color: $wcm-med-gray;
//       margin: 0;
//         line-height: inherit;
//       font-size: 15px; //, 24px);
//     }
//   }

//   .field-name-field-member-email {
//     a {
//       &:link, &:visited {
//         color: inherit;
//       }
//     }
//   }
// }

/* Directory and Member Bio */
ul.profile-links-list {
  margin: 0;
  @include clearfix();
  li {
    list-style: none;
    float: left;
    margin: 0 1em 0 0;
    padding: 0 1em 0 0;
    border-right: 2px solid $wcm-border-gray;
    &.first {
    }
    &.last {
      padding-right: 0;
      border-right: none
    }
  }
}

/* Leadership - Member Bio */
.member-bio {
  .side {
    &.left {
      padding-bottom: 1em;
    }
    @include breakpoint($sm) {
      float: left;
      &.left {
        width: 21.5%;
        min-height: 1px;
        padding-right: 1.25em; // about 50px at wide2
      }
      &.right {
        width: 78.5%;
        padding-left: 1.25em;
        padding-right: 5em; // about 100px at wide2
      }
    }
  }

  h2, h3 {
    margin: 0 0 rhythm(.2) 0;
    color: $wcm-red;
    @include font-size(30px);
  }
  .field-name-field-member-department,
  .field-name-field-member-title {
    text-transform: uppercase;
    @include font-size(18px);
    color: $wcm-dark-gray;
//    // font-family: $frutiger;
  }

  .field-body {
    color: $wcm-dark-gray;
  }

  .contact {
    .field {
      @include font-size(16px, 24px);
      // margin: rhythm(.1) 0;
//      // font-family: $frutiger;
      color: $wcm-dark-gray;
      a {
        &:link, &:visited {
          color: inherit;
        }
        &:hover, &:active {
          // color: $color-blue;
        }
      }
    }
  }

  ul.profile-links-list {
    margin-top: rhythm(.5);
    // font-family: $frutiger;
    @include font-size(16px, 20px);
  }
}
.view-content-leadership-members {
  margin: 3.75em 0; // about 75px at wide2

  .views-row {
    margin-top: 1em;
    @include breakpoint($sm) {
      margin-top: 4.5em;
    }
    .side.right {
      border-bottom: 1px solid $wcm-bg-gray;
      padding-bottom: 1em;
      @include breakpoint($sm) {
        padding-bottom: 4.5em;
      }
    }

    &.views-row-first {
      margin-top: 0;
    }
    &.views-row-last {
      .side.right {
        border-bottom: none;
      }
    }
  }
}


@mixin photo-caption {
  display: block;
  font-style: italic;
  font-weight: normal;
  @include font-size(14px, 18px);
  margin: 9px 0;
}

.media-element-container {
  display: block;
  max-width: 100%;
  @include breakpoint($sm) {
    display: inline-block;
    max-height: none;
    vertical-align: top;
  }

  img {
    display: block;
    margin: 0;
    max-width: 100%;
    height: auto;
    vertical-align: bottom;
    max-height: 220px;
    @include breakpoint($sm) {
      max-height: none;
    }
  }

  span.field-name-field-file-image-caption {
    @include photo-caption;
  }

  &.media-embed_floatleft {
    margin: .5em 0;
    @include breakpoint($md) {
      float: left;
      margin: .5em 2em 1em 0;
      max-width: 29%;
    }
  }
  &.media-embed_floatright {
    margin: .5em 0;
    @include breakpoint($md) {
      float: right;
      margin: .5em 0 1em 2em;
      max-width: 29%;
    }
  }

  &.media-embed_oneoftwo {
    margin-top: 12px;
    margin-left: 17.5%;
    margin-right: -.25em; // because of inline-block
    max-width: 32%;
    @include breakpoint($md) {
      margin-top: 20px;
//      max-width: 940px * .32;
    }
    @include break-wide {
      margin-top: 25px;
//      max-width: 1100px * .32;
    }
//    @include break-wide2 {
//      margin-top: 25px;
//    }
  }
  + .media-element-container.media-embed_oneoftwo {
    margin-left: 18px;
    margin-right: auto;
  }

  &.media-embed_center {
    max-width: 75%;
    margin: 1em auto;
    display: block;
    img {
      margin: 0 auto;
    }
  }
  &.media-embed_fullwidth {
    max-width: 100%;
    margin: 1em 0;
    display: block;
  }
}

/* Education Page - Hack */
.page-education {
  @include breakpoint($sm) {
    .sidebar.information-column {
//      @include leader(3, $property: padding);
      padding-top: 2.4em;
      .panel-panel-inner {
        margin-top: 0;
      }
      // Needed to lower the photo link blocks down to align better with text.
    }
  }
}

/* Simple CSS3 fade in for some elements */
.hide-initially {
  .js & {
    opacity: 0;
    @include transition(opacity 350ms);
    @include transition-delay(100ms);
  }
  &.make-visible {
    opacity: 1;
  }
}

/* Forms .. infield labels .. circle arrow rounded button .. etc */
form {
  button[type="submit"] {
    i {
      display: none;
    }
  }
}

footer form.mcc-initialized {
    h4 {
        padding-bottom: 8px;
    }

    .mailchimp-newsletter-mergefields {
        @include breakpoint($sm) {
            float: left;
            margin-right: 10px;
        }

        .form-item {
            @include breakpoint($xs) {
                margin-bottom: 15px;
            }
        }
    }
    .form-actions {
        margin-top: 0;
    }
}


form.mcc-initialized {

  &.global-search span>div {
    display: inline !important;
  }

  .form-item, .views-exposed-widget {
    &.infieldlabel {
      position: relative;
      display: inline-block;

      label {
//         position: absolute;
//         top: 0;
//         left:0;
//         cursor: text;
//         width: 100%;
//         color: $wcm-border-gray;
//         font-weight: normal;
// //        font-size: 16px;
//         padding: 8px 1em;
        .form-required {
          visibility: hidden;
        }
      }
    }
  }

  /*.form-type-textfield {
    input[type="text"] {
      width: 100%;
      font-size: 16px;
      height: 2.8em;
      padding: 0 3em 0 1em;
      border: 1px solid $wcm-border-gray;
      border-radius: 0;
      -webkit-appearance: none;
      &::-ms-clear {
        display: none;
      }
    }
  }*/

  button[type="submit"] {
    .js & {
      -webkit-appearance: none;
      background: none;
      border: none;
      display: inline-block;
      vertical-align: middle;
      padding: 0 0 .25em 0;
      visibility: hidden;
      &.js-hide {
        display: none;
      }
    }
    span {
      .wf-fontello-n4-active & {
        display: none; // get rid of the text
      }
    }
    i {
      display: inline;
      color: darken($wcm-med-gray, 10%);
      font-size: 1.8em;
      @include transition(color 250ms);
      &:hover {
      }
      display: none;
    }
    &:hover, &:focus {
      i {
        color: $wcm-border-gray;
      }
    }
    .wf-fontello-n4-active & {
      visibility: visible;
      span {
        display: none;
      }
      i {
        display: inline-block;
      }
    }
  }

  /* Newsletter signup */
  &.inline-submit-button {
    position: relative;
    display: inline-block;
    .form-type-textfield {
      margin: 0;
    }

    button[type="submit"] {
      position: absolute;
      bottom: 0px;
      right: 0;
      padding-bottom: 0;
    }
  }
}

.panel-onecol .col-md-12 .container-fluid {
    padding-left: 0;
    padding-right: 0;
}
.panel-twocol, .panel-hero-3col {
    .main-content .container-fluid {
        padding-left: 0;
        padding-right: 0;
    }
}

.hero-header {

    text-align: center;
    position: relative;
    margin-bottom: 20px;

    .title-wrap {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background: rgba(black, .35);
    @include breakpoint($sm) {
      // CSS3Pie (photoshop extension) came up with:
      // background: linear-gradient(to top, #000, rgba(0,0,0,0) 59%);
      // but this seems too dark. so going with:
      background: -webkit-linear-gradient(bottom, rgba(0,0,0,.5), rgba(0,0,0,0) 79%);
      background: -moz-linear-gradient(bottom, rgba(0,0,0,.5), rgba(0,0,0,0) 79%);
      background: -o-linear-gradient(bottom, rgba(0,0,0,.5), rgba(0,0,0,0) 79%);
      background: -ms-linear-gradient(bottom, rgba(0,0,0,.5), rgba(0,0,0,0) 79%);
      background: linear-gradient(to top, rgba(0,0,0,.5), rgba(0, 0, 0, 0) 79%);
    }
    &:before {
      // Causes vertical centering
      content: '';
      display: inline-block;
      height: 100%;
      vertical-align: middle;
      margin-right: -0.25em; /* Adjusts for spacing */
    }
    h1 {
      width: 98%;
      display: inline-block;
      vertical-align: middle;
      @include breakpoint($md) {
        width: 80%;
      }
      color: white;
//      font-family: $palatino;
//      font-weight: normal;
//      line-height: 1;
      margin: .5em 0 0 0;
      @include breakpoint($xs) {
        font-size: 2.222rem;
      }
    }
  }

    img {
//        width: 100%;
//        height: auto;
//        margin: 0 0 20px;
    }
}


.node-type-research-program .pane-node-body div:nth-child(1) h3:first-child,
.panel-twocol .col-md-12 .pane-node-body div:nth-child(1) h3:first-child,
.panel-twocol .col-sm-12 .pane-node-body div:nth-child(1) h3:first-child,
.panel-twocol .col-md-9 .pane-node-body div:nth-child(1) h3:first-child {
    font-family: $wcm-regular;
//    color: $wcm-dark-gray;
    line-height: 1.4;
}

/* =Default Select Elements
----------------------------------------------------------*/

.form-select {
  max-width: none;
  height: 50px;
  border: 1px solid $wcm-border-gray;
  box-shadow: none;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath d='M207 381.5L12.7 187.1c-9.4-9.4-9.4-24.6 0-33.9l22.7-22.7c9.4-9.4 24.5-9.4 33.9 0l154.7 154 154.7-154c9.4-9.3 24.5-9.3 33.9 0l22.7 22.7c9.4 9.4 9.4 24.6 0 33.9L241 381.5c-9.4 9.3-24.6 9.3-34 0z' fill='%23cf4520'/%3E%3C/svg%3E");
	background-repeat: no-repeat;
	background-position: right 15px top 50%;
  background-size: 20px 20px;
  padding-right: 50px;
}
