@charset "UTF-8";
/**
 * Example Stub for creating WCMC Subthemes
 *
 */
/**
 * This file is used to customize the default Bootstrap core variables.
 * A list of options can be found here:
 * https://github.com/twbs/bootstrap-sass/blob/3.2-stable/assets/stylesheets/bootstrap/_variables.scss
 */
/* =WCM Colors and Fonts
----------------------------------------------------------*/
/* !WARNING!
When changing the colors below also change them in:
profiles/wcmc/modules/custom/panopoly_override/features/panopoly_wysiwyg.inc:53,
inside the panopoly_override_wysiwyg_editor_settings_alter() function.
JIRA Ticket: WEBENG-843
*/
/* =Scaffolding
----------------------------------------------------------*/
/* =Typography
----------------------------------------------------------*/
/* =Grid System
Padding between columns. Gets divided in half for the left and right.
----------------------------------------------------------*/
/* =Buttons
Define text, background and border color.
----------------------------------------------------------*/
/* =Forms
----------------------------------------------------------*/
/* =Pagination
----------------------------------------------------------*/
/**
 * Custom Mixins
 *
 */
/* =Breakpoint Utility
// https://medium.com/developing-with-sass/refactoring-my-simple-sass-breakpoint-mixin-71205dd6badd
----------------------------------------------------------*/
/* =Container Wrap
This is used when you can't add the class="container" markup,
just use this mixin on a wrapping element instead.
----------------------------------------------------------*/
/* =Fontello Icon Font
----------------------------------------------------------*/
/* =Dead Center within parent
----------------------------------------------------------*/
/* =Vertically Center
----------------------------------------------------------*/
/* =Button SubNav Style
----------------------------------------------------------*/
/* =Site-specific styles
----------------------------------------------------------*/
#primary-nav .level-1 {
  width: auto; }
  @media screen and (min-width: 768px) {
    #primary-nav .level-1 {
      padding: 8px 20px; } }

.title-bar {
  font-size: 22px; }

.brand__lockup img {
  height: 84px; }

h1, h2, h3, h4, h5, h6 {
  font-family: "1898Sans-Regular", sans-serif; }

/**
 * Vertical Centering
 *
 * Apply this to container. Inner content, to be vertical centered, must
 * get display: inline-block.
 * Container must have a defined height. Possibly use absolute positioning.
 */
/* Homepage layout (including width radix overrides) */
.page-home .pane-title {
  color: #b31b1b;
  font-weight: normal;
  line-height: 1.25;
  overflow: hidden;
  padding-right: 10px;
  margin: 0 0 .75em 0; }
  @media screen and (min-width: 768px) {
    .page-home .pane-title {
      min-height: 3em; } }
  .page-home .pane-title a {
    /*&:hover, &:active {
        // color: $color-blue;
      }*/ }
    .page-home .pane-title a:link, .page-home .pane-title a:visited {
      color: inherit; }

.mcc-homepage {
  margin: 0 auto;
  padding: 0 5px; }
  @media screen and (min-width: 768px) {
    .mcc-homepage {
      max-width: 980px; } }
  @media screen and (min-width: 1200px) {
    .mcc-homepage {
      max-width: 1180px; } }
  @media screen and (max-width: 767px) {
    .mcc-homepage .row {
      padding: 0 15px; }
      .mcc-homepage .row > .panel-panel {
        padding: 0; } }
  @media screen and (min-width: 768px) {
    .mcc-homepage .row {
      padding: 0 15px; }
      .mcc-homepage .row > .panel-panel {
        padding: 0; } }
  .mcc-homepage .feature-carousel {
    margin-bottom: 0; }
    @media screen and (min-width: 768px) {
      .mcc-homepage .feature-carousel {
        width: 59.76%;
        float: left; } }
  .mcc-homepage .feature-grid {
    overflow: hidden; }
    @media screen and (min-width: 768px) {
      .mcc-homepage .feature-grid {
        width: 40.23%;
        float: left; } }
  .mcc-homepage .row {
    margin-bottom: 5px; }
    @media screen and (min-width: 768px) {
      .mcc-homepage .row {
        margin-bottom: 1em; } }
  @media screen and (min-width: 992px) {
    .mcc-homepage .row2 {
      display: block;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-wrap: wrap;
      flex-wrap: wrap; } }
  .mcc-homepage .row2col1 {
    margin-bottom: 5px;
    width: 100%; }
    @media screen and (min-width: 768px) {
      .mcc-homepage .row2col1 {
        margin-bottom: 0px; } }
    @media screen and (min-width: 992px) {
      .mcc-homepage .row2col1 {
        margin-bottom: 0;
        display: block;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
        width: 59.76%;
        float: left; } }
    .mcc-homepage .row2col1 .panel-panel-inner {
      background: #f7f7f7;
      padding: 10px;
      position: relative; }
      @media screen and (max-width: 767px) {
        .mcc-homepage .row2col1 .panel-panel-inner {
          padding: 15px; } }
      @media screen and (min-width: 768px) {
        .mcc-homepage .row2col1 .panel-panel-inner {
          padding: 20px 15px 30px 30px;
          display: block;
          display: -webkit-flex;
          display: flex;
          -webkit-flex-wrap: wrap;
          flex-wrap: wrap; } }
  .mcc-homepage .row2col2 {
    display: none; }
    @media screen and (min-width: 768px) {
      .mcc-homepage .row2col2 {
        display: block;
        width: 33%; } }
    @media screen and (min-width: 992px) {
      .mcc-homepage .row2col2 {
        display: block;
        display: block;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
        width: 23.33%;
        float: left; } }
    .mcc-homepage .row2col2 .panel-panel-inner {
      padding: 10px;
      position: relative; }
      @media screen and (max-width: 767px) {
        .mcc-homepage .row2col2 .panel-panel-inner {
          padding: 20px; } }
      @media screen and (min-width: 768px) {
        .mcc-homepage .row2col2 .panel-panel-inner {
          padding: 20px 20px 25px 30px;
          display: block;
          display: -webkit-flex;
          display: flex;
          -webkit-flex-wrap: wrap;
          flex-wrap: wrap; } }
  .mcc-homepage .row2col3 {
    position: relative; }
    @media screen and (min-width: 768px) {
      .mcc-homepage .row2col3 {
        width: 67%; } }
    @media screen and (min-width: 992px) {
      .mcc-homepage .row2col3 {
        display: block;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
        width: 40.13%;
        min-height: 470px;
        float: left; } }
    @media screen and (min-width: 1200px) {
      .mcc-homepage .row2col3 {
        min-height: 515px; } }
    @media screen and (min-width: 768px) {
      .mcc-homepage .row2col3 .panel-panel-inner {
        display: block;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap; } }

/* Sidebar */
.information-column h3.pane-title {
  color: #555555;
  font-size: 18px; }

.information-sidebar .field-name-field-external-links a,
.information-sidebar .field-name-field-quick-links-links a,
.information-sidebar .pane-bundle-quick-links .fieldable-panels-pane a,
.information-sidebar .panel-pane .pane-content a, .information-sidebar .panel-pane a, .information-column .field-name-field-external-links a,
.information-column .field-name-field-quick-links-links a,
.information-column .pane-bundle-quick-links .fieldable-panels-pane a,
.information-column .panel-pane .pane-content a, .information-column .panel-pane a {
  /*display: block;
      padding-right: 1.25em;*/
  font-size: 13px; }
  .information-sidebar .field-name-field-external-links a:not(.linked-photo):not(.external-link):after,
  .information-sidebar .field-name-field-quick-links-links a:not(.linked-photo):not(.external-link):after,
  .information-sidebar .pane-bundle-quick-links .fieldable-panels-pane a:not(.linked-photo):not(.external-link):after,
  .information-sidebar .panel-pane .pane-content a:not(.linked-photo):not(.external-link):after, .information-sidebar .panel-pane a:not(.linked-photo):not(.external-link):after, .information-column .field-name-field-external-links a:not(.linked-photo):not(.external-link):after,
  .information-column .field-name-field-quick-links-links a:not(.linked-photo):not(.external-link):after,
  .information-column .pane-bundle-quick-links .fieldable-panels-pane a:not(.linked-photo):not(.external-link):after,
  .information-column .panel-pane .pane-content a:not(.linked-photo):not(.external-link):after, .information-column .panel-pane a:not(.linked-photo):not(.external-link):after {
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: .2em;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    line-height: 1em;
    margin-left: .2em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: ""; }
  .information-sidebar .field-name-field-external-links a:not(.linked-photo):not(.external-link):hover:after, .information-sidebar .field-name-field-external-links a:not(.linked-photo):not(.external-link):active:after,
  .information-sidebar .field-name-field-quick-links-links a:not(.linked-photo):not(.external-link):hover:after,
  .information-sidebar .field-name-field-quick-links-links a:not(.linked-photo):not(.external-link):active:after,
  .information-sidebar .pane-bundle-quick-links .fieldable-panels-pane a:not(.linked-photo):not(.external-link):hover:after,
  .information-sidebar .pane-bundle-quick-links .fieldable-panels-pane a:not(.linked-photo):not(.external-link):active:after,
  .information-sidebar .panel-pane .pane-content a:not(.linked-photo):not(.external-link):hover:after,
  .information-sidebar .panel-pane .pane-content a:not(.linked-photo):not(.external-link):active:after, .information-sidebar .panel-pane a:not(.linked-photo):not(.external-link):hover:after, .information-sidebar .panel-pane a:not(.linked-photo):not(.external-link):active:after, .information-column .field-name-field-external-links a:not(.linked-photo):not(.external-link):hover:after, .information-column .field-name-field-external-links a:not(.linked-photo):not(.external-link):active:after,
  .information-column .field-name-field-quick-links-links a:not(.linked-photo):not(.external-link):hover:after,
  .information-column .field-name-field-quick-links-links a:not(.linked-photo):not(.external-link):active:after,
  .information-column .pane-bundle-quick-links .fieldable-panels-pane a:not(.linked-photo):not(.external-link):hover:after,
  .information-column .pane-bundle-quick-links .fieldable-panels-pane a:not(.linked-photo):not(.external-link):active:after,
  .information-column .panel-pane .pane-content a:not(.linked-photo):not(.external-link):hover:after,
  .information-column .panel-pane .pane-content a:not(.linked-photo):not(.external-link):active:after, .information-column .panel-pane a:not(.linked-photo):not(.external-link):hover:after, .information-column .panel-pane a:not(.linked-photo):not(.external-link):active:after {
    text-decoration: none; }

.information-sidebar .field-name-field-external-links > .item-list ul li, .information-sidebar .field-name-field-external-links > .item-list ul .field-item, .information-sidebar .field-name-field-external-links > .field-items li, .information-sidebar .field-name-field-external-links > .field-items .field-item,
.information-sidebar .field-name-field-quick-links-links > .item-list ul li,
.information-sidebar .field-name-field-quick-links-links > .item-list ul .field-item,
.information-sidebar .field-name-field-quick-links-links > .field-items li,
.information-sidebar .field-name-field-quick-links-links > .field-items .field-item,
.information-sidebar .pane-bundle-quick-links .fieldable-panels-pane > .item-list ul li,
.information-sidebar .pane-bundle-quick-links .fieldable-panels-pane > .item-list ul .field-item,
.information-sidebar .pane-bundle-quick-links .fieldable-panels-pane > .field-items li,
.information-sidebar .pane-bundle-quick-links .fieldable-panels-pane > .field-items .field-item,
.information-sidebar .panel-pane .pane-content > .item-list ul li,
.information-sidebar .panel-pane .pane-content > .item-list ul .field-item,
.information-sidebar .panel-pane .pane-content > .field-items li,
.information-sidebar .panel-pane .pane-content > .field-items .field-item, .information-sidebar .panel-pane > .item-list ul li, .information-sidebar .panel-pane > .item-list ul .field-item, .information-sidebar .panel-pane > .field-items li, .information-sidebar .panel-pane > .field-items .field-item, .information-column .field-name-field-external-links > .item-list ul li, .information-column .field-name-field-external-links > .item-list ul .field-item, .information-column .field-name-field-external-links > .field-items li, .information-column .field-name-field-external-links > .field-items .field-item,
.information-column .field-name-field-quick-links-links > .item-list ul li,
.information-column .field-name-field-quick-links-links > .item-list ul .field-item,
.information-column .field-name-field-quick-links-links > .field-items li,
.information-column .field-name-field-quick-links-links > .field-items .field-item,
.information-column .pane-bundle-quick-links .fieldable-panels-pane > .item-list ul li,
.information-column .pane-bundle-quick-links .fieldable-panels-pane > .item-list ul .field-item,
.information-column .pane-bundle-quick-links .fieldable-panels-pane > .field-items li,
.information-column .pane-bundle-quick-links .fieldable-panels-pane > .field-items .field-item,
.information-column .panel-pane .pane-content > .item-list ul li,
.information-column .panel-pane .pane-content > .item-list ul .field-item,
.information-column .panel-pane .pane-content > .field-items li,
.information-column .panel-pane .pane-content > .field-items .field-item, .information-column .panel-pane > .item-list ul li, .information-column .panel-pane > .item-list ul .field-item, .information-column .panel-pane > .field-items li, .information-column .panel-pane > .field-items .field-item {
  font-size: 13px;
  list-style: none;
  margin: 0 0 .5em 0;
  display: block; }
  .information-sidebar .field-name-field-external-links > .item-list ul li a, .information-sidebar .field-name-field-external-links > .item-list ul .field-item a, .information-sidebar .field-name-field-external-links > .field-items li a, .information-sidebar .field-name-field-external-links > .field-items .field-item a,
  .information-sidebar .field-name-field-quick-links-links > .item-list ul li a,
  .information-sidebar .field-name-field-quick-links-links > .item-list ul .field-item a,
  .information-sidebar .field-name-field-quick-links-links > .field-items li a,
  .information-sidebar .field-name-field-quick-links-links > .field-items .field-item a,
  .information-sidebar .pane-bundle-quick-links .fieldable-panels-pane > .item-list ul li a,
  .information-sidebar .pane-bundle-quick-links .fieldable-panels-pane > .item-list ul .field-item a,
  .information-sidebar .pane-bundle-quick-links .fieldable-panels-pane > .field-items li a,
  .information-sidebar .pane-bundle-quick-links .fieldable-panels-pane > .field-items .field-item a,
  .information-sidebar .panel-pane .pane-content > .item-list ul li a,
  .information-sidebar .panel-pane .pane-content > .item-list ul .field-item a,
  .information-sidebar .panel-pane .pane-content > .field-items li a,
  .information-sidebar .panel-pane .pane-content > .field-items .field-item a, .information-sidebar .panel-pane > .item-list ul li a, .information-sidebar .panel-pane > .item-list ul .field-item a, .information-sidebar .panel-pane > .field-items li a, .information-sidebar .panel-pane > .field-items .field-item a, .information-column .field-name-field-external-links > .item-list ul li a, .information-column .field-name-field-external-links > .item-list ul .field-item a, .information-column .field-name-field-external-links > .field-items li a, .information-column .field-name-field-external-links > .field-items .field-item a,
  .information-column .field-name-field-quick-links-links > .item-list ul li a,
  .information-column .field-name-field-quick-links-links > .item-list ul .field-item a,
  .information-column .field-name-field-quick-links-links > .field-items li a,
  .information-column .field-name-field-quick-links-links > .field-items .field-item a,
  .information-column .pane-bundle-quick-links .fieldable-panels-pane > .item-list ul li a,
  .information-column .pane-bundle-quick-links .fieldable-panels-pane > .item-list ul .field-item a,
  .information-column .pane-bundle-quick-links .fieldable-panels-pane > .field-items li a,
  .information-column .pane-bundle-quick-links .fieldable-panels-pane > .field-items .field-item a,
  .information-column .panel-pane .pane-content > .item-list ul li a,
  .information-column .panel-pane .pane-content > .item-list ul .field-item a,
  .information-column .panel-pane .pane-content > .field-items li a,
  .information-column .panel-pane .pane-content > .field-items .field-item a, .information-column .panel-pane > .item-list ul li a, .information-column .panel-pane > .item-list ul .field-item a, .information-column .panel-pane > .field-items li a, .information-column .panel-pane > .field-items .field-item a {
    /*font-size: inherit;
          display: inline-block;
          padding-right: 1.25em;
          box-sizing: content-box;
          position: relative;*/
    /*&:after {
            position: absolute;
            top: 0px;
            right: -5px;
            font-size: 12px;
            padding-top: 1px;
          }*/ }

.information-sidebar .panel-pane .pane-content, .information-sidebar .fieldable-panels-pane, .information-column .panel-pane .pane-content, .information-column .fieldable-panels-pane {
  padding-bottom: 1em; }

.information-sidebar .pane-bundle-photo-quick-links, .information-column .pane-bundle-photo-quick-links {
  padding-right: 0; }
  .information-sidebar .pane-bundle-photo-quick-links .panel-pane .pane-content a.linked-photo, .information-column .pane-bundle-photo-quick-links .panel-pane .pane-content a.linked-photo {
    display: block;
    margin-bottom: .75em;
    max-width: 308px;
    overflow: hidden;
    position: relative; }
    .information-sidebar .pane-bundle-photo-quick-links .panel-pane .pane-content a.linked-photo:link, .information-sidebar .pane-bundle-photo-quick-links .panel-pane .pane-content a.linked-photo:visited, .information-column .pane-bundle-photo-quick-links .panel-pane .pane-content a.linked-photo:link, .information-column .pane-bundle-photo-quick-links .panel-pane .pane-content a.linked-photo:visited {
      text-decoration: none;
      color: #fff; }
    .information-sidebar .pane-bundle-photo-quick-links .panel-pane .pane-content a.linked-photo:hover, .information-sidebar .pane-bundle-photo-quick-links .panel-pane .pane-content a.linked-photo:active, .information-column .pane-bundle-photo-quick-links .panel-pane .pane-content a.linked-photo:hover, .information-column .pane-bundle-photo-quick-links .panel-pane .pane-content a.linked-photo:active {
      text-decoration: none;
      color: #fff; }
    .information-sidebar .pane-bundle-photo-quick-links .panel-pane .pane-content a.linked-photo:after, .information-column .pane-bundle-photo-quick-links .panel-pane .pane-content a.linked-photo:after {
      content: '';
      display: block;
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.3);
      background: -webkit-linear-gradient(bottom, #000, rgba(0, 0, 0, 0) 59%);
      background: -moz-linear-gradient(bottom, #000, rgba(0, 0, 0, 0) 59%);
      background: -o-linear-gradient(bottom, #000, rgba(0, 0, 0, 0) 59%);
      background: -ms-linear-gradient(bottom, #000, rgba(0, 0, 0, 0) 59%);
      background: linear-gradient(to top, #000, rgba(0, 0, 0, 0) 59%); }
  .information-sidebar .pane-bundle-photo-quick-links a.linked-photo, .information-column .pane-bundle-photo-quick-links a.linked-photo {
    display: block;
    margin-bottom: .75em;
    padding: 0;
    font-family: inherit;
    font-size: 20px;
    max-width: 308px;
    overflow: hidden;
    position: relative; }
    .information-sidebar .pane-bundle-photo-quick-links a.linked-photo:link, .information-sidebar .pane-bundle-photo-quick-links a.linked-photo:visited, .information-column .pane-bundle-photo-quick-links a.linked-photo:link, .information-column .pane-bundle-photo-quick-links a.linked-photo:visited {
      text-decoration: none;
      color: #fff; }
    .information-sidebar .pane-bundle-photo-quick-links a.linked-photo:hover, .information-sidebar .pane-bundle-photo-quick-links a.linked-photo:active, .information-column .pane-bundle-photo-quick-links a.linked-photo:hover, .information-column .pane-bundle-photo-quick-links a.linked-photo:active {
      text-decoration: none;
      color: #fff; }
    .information-sidebar .pane-bundle-photo-quick-links a.linked-photo:after, .information-column .pane-bundle-photo-quick-links a.linked-photo:after {
      content: '';
      display: block;
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.3);
      background: -webkit-linear-gradient(bottom, #000, rgba(0, 0, 0, 0) 59%);
      background: -moz-linear-gradient(bottom, #000, rgba(0, 0, 0, 0) 59%);
      background: -o-linear-gradient(bottom, #000, rgba(0, 0, 0, 0) 59%);
      background: -ms-linear-gradient(bottom, #000, rgba(0, 0, 0, 0) 59%);
      background: linear-gradient(to top, #000, rgba(0, 0, 0, 0) 59%); }
  .information-sidebar .pane-bundle-photo-quick-links .link-image, .information-column .pane-bundle-photo-quick-links .link-image {
    z-index: 0; }
    .information-sidebar .pane-bundle-photo-quick-links .link-image img, .information-column .pane-bundle-photo-quick-links .link-image img {
      -webkit-transition: -webkit-transform .5s;
      -moz-transition: -moz-transform .5s;
      -ms-transition: -ms-transform .5s;
      -o-transition: -o-transform .5s;
      transition: transform .5s;
      -webkit-backface-visibility: hidden;
      -webkit-transform: translateZ(0) scale(1, 1); }
  .information-sidebar .pane-bundle-photo-quick-links a:hover .link-image img, .information-sidebar .pane-bundle-photo-quick-links a:active .link-image img, .information-column .pane-bundle-photo-quick-links a:hover .link-image img, .information-column .pane-bundle-photo-quick-links a:active .link-image img {
    -webkit-transform: scale(1.2);
    transform: scale(1.2); }
  .information-sidebar .pane-bundle-photo-quick-links .link-title, .information-column .pane-bundle-photo-quick-links .link-title {
    position: absolute;
    z-index: 2;
    bottom: 0px;
    padding: 0 1em 1em 1em;
    font-size: 16px;
    line-height: 22px;
    -webkit-backface-visibility: hidden;
    -webkit-transform: translateZ(0) scale(1, 1); }

.pane-mcc-programs-program-members.panel-pane .pane-content .item-list ul li a:after {
  display: none; }

/* Misc Radix layout override */
/* For Visiting Us page: */
@media screen and (min-width: 992px) {
  .radix-layouts-secondcolumn1 {
    padding-right: 30px; } }

@media screen and (min-width: 992px) {
  .radix-layouts-secondcolumn2 {
    padding-left: 30px; } }

/** MCC Layouts (mcc_layouts) custom layouts **/
@media screen and (min-width: 768px) {
  .panel-hero-3col .information-sidebar .panels-ipe-region, .panel-hero-3col .information-column .panels-ipe-region {
    margin: 1.8em 0;
    padding: 0 0 0 1.5em;
    border-left: 1px solid #dddddd; } }

.mcc-layouts-sidebar .mcc-layouts-main-content .panel-panel-inner {
  padding: 0 1em 0 0; }

/* Home Features - Carousel & Grid */
.view-content-features-carousel {
  padding-right: 1px;
  position: relative; }
  .view-content-features-carousel .views-row + .views-row {
    display: none; }
  .view-content-features-carousel .bx-viewport .views-row + .views-row {
    display: block; }
  .view-content-features-carousel .views-row {
    background: #f7f7f7; }
  .view-content-features-carousel .views-row .node {
    position: relative; }
    .view-content-features-carousel .views-row .node .text {
      position: absolute;
      z-index: 5;
      bottom: 0;
      right: 0;
      color: #fff;
      padding: 0 20px 20px 0; }
    .view-content-features-carousel .views-row .node .image {
      position: relative;
      z-index: 0; }
      .view-content-features-carousel .views-row .node .image:after {
        content: '';
        display: block;
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.3);
        background: -webkit-linear-gradient(bottom, #000, rgba(0, 0, 0, 0.06) 45%, rgba(0, 0, 0, 0) 48%);
        background: -moz-linear-gradient(bottom, #000, rgba(0, 0, 0, 0.06) 45%, rgba(0, 0, 0, 0) 48%);
        background: -o-linear-gradient(bottom, #000, rgba(0, 0, 0, 0.06) 45%, rgba(0, 0, 0, 0) 48%);
        background: -ms-linear-gradient(bottom, #000, rgba(0, 0, 0, 0.06) 45%, rgba(0, 0, 0, 0) 48%);
        background: linear-gradient(to top, #000, rgba(0, 0, 0, 0.06) 45%, rgba(0, 0, 0, 0) 48%); }
  .view-content-features-carousel .bx-controls-direction a {
    display: none;
    opacity: 0;
    -webkit-transition: opacity 250ms;
    -o-transition: opacity 250ms;
    transition: opacity 250ms;
    position: absolute;
    top: 50%;
    margin-top: -20px;
    min-width: 40px;
    min-height: 40px;
    color: #fff;
    font-size: 40px; }
    @media screen and (min-width: 768px) {
      .view-content-features-carousel .bx-controls-direction a {
        display: block; } }
    .view-content-features-carousel .bx-controls-direction a:link, .view-content-features-carousel .bx-controls-direction a:visited {
      color: #fff; }
    .view-content-features-carousel .bx-controls-direction a:hover, .view-content-features-carousel .bx-controls-direction a:active {
      color: #b31b1b; }
    .view-content-features-carousel .bx-controls-direction a span {
      display: none; }
    .view-content-features-carousel .bx-controls-direction a.bx-prev {
      left: 5px; }
      .view-content-features-carousel .bx-controls-direction a.bx-prev .icon-circle-arrow-left:after {
        font-family: "fontello";
        font-style: normal;
        font-weight: normal;
        speak: none;
        display: inline-block;
        text-decoration: inherit;
        width: 1em;
        margin-right: .2em;
        text-align: center;
        font-variant: normal;
        text-transform: none;
        line-height: 1em;
        margin-left: .2em;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        content: ""; }
      .view-content-features-carousel .bx-controls-direction a.bx-prev .icon-circle-arrow-left:hover:after, .view-content-features-carousel .bx-controls-direction a.bx-prev .icon-circle-arrow-left:active:after {
        text-decoration: none; }
    .view-content-features-carousel .bx-controls-direction a.bx-next {
      right: 5px; }
      .view-content-features-carousel .bx-controls-direction a.bx-next .icon-circle-arrow-right:after {
        font-family: "fontello";
        font-style: normal;
        font-weight: normal;
        speak: none;
        display: inline-block;
        text-decoration: inherit;
        width: 1em;
        margin-right: .2em;
        text-align: center;
        font-variant: normal;
        text-transform: none;
        line-height: 1em;
        margin-left: .2em;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        content: ""; }
      .view-content-features-carousel .bx-controls-direction a.bx-next .icon-circle-arrow-right:hover:after, .view-content-features-carousel .bx-controls-direction a.bx-next .icon-circle-arrow-right:active:after {
        text-decoration: none; }
  .view-content-features-carousel:hover .bx-controls-direction a {
    opacity: 1; }
  .view-content-features-carousel .bx-pager {
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
    text-align: center; }
  .view-content-features-carousel .bx-pager-item {
    display: inline; }
    .view-content-features-carousel .bx-pager-item a {
      display: inline-block;
      width: 12px;
      height: 12px;
      border-radius: 6px;
      background: #fff;
      overflow: hidden;
      text-indent: -999px;
      margin: 0 3px; }
      .view-content-features-carousel .bx-pager-item a.active, .view-content-features-carousel .bx-pager-item a:hover {
        background: #b31b1b; }

.node.feature.carousel, .node.feature.grid {
  position: relative; }
  .node.feature.carousel img, .node.feature.grid img {
    display: block;
    width: 100%; }
  .node.feature.carousel .text, .node.feature.grid .text {
    position: absolute;
    z-index: 5;
    bottom: 0;
    right: 0;
    color: #fff;
    padding: 0 20px 20px 20px; }
  .node.feature.carousel .image, .node.feature.grid .image {
    position: relative;
    z-index: 0; }
    .node.feature.carousel .image:after, .node.feature.grid .image:after {
      content: '';
      display: block;
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.3);
      background: -webkit-linear-gradient(bottom, #000, rgba(0, 0, 0, 0.07) 63%, rgba(0, 0, 0, 0) 68%);
      background: -moz-linear-gradient(bottom, #000, rgba(0, 0, 0, 0.07) 63%, rgba(0, 0, 0, 0) 68%);
      background: -o-linear-gradient(bottom, #000, rgba(0, 0, 0, 0.07) 63%, rgba(0, 0, 0, 0) 68%);
      background: -ms-linear-gradient(bottom, #000, rgba(0, 0, 0, 0.07) 63%, rgba(0, 0, 0, 0) 68%);
      background: linear-gradient(to top, #000, rgba(0, 0, 0, 0.07) 63%, rgba(0, 0, 0, 0) 68%); }
  .node.feature.carousel h4, .node.feature.grid h4 {
    margin: 0;
    line-height: 1.1;
    color: inherit; }

.node.feature.grid a {
  display: block;
  overflow: hidden;
  -webkit-backface-visibility: hidden; }
  .node.feature.grid a img {
    -webkit-transition: transform 0.5s;
    -o-transition: transform 0.5s;
    transition: transform 0.5s; }
  .node.feature.grid a:hover img {
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2); }

.view-content-features-grid .view-content {
  margin: 5px -5px -5px 0; }
  .view-content-features-grid .view-content:before, .view-content-features-grid .view-content:after {
    content: " ";
    display: table; }
  .view-content-features-grid .view-content:after {
    clear: both; }
  @media screen and (min-width: 768px) {
    .view-content-features-grid .view-content {
      margin-top: 0px;
      margin-right: -1px;
      margin-bottom: -1px; } }

.view-content-features-grid .views-row {
  width: 49.90%;
  float: left;
  padding-right: 5px;
  padding-bottom: 5px;
  overflow: hidden;
  /*@include breakpoint( (min: 600px) and (orientation: landscape) ) {
        padding-right: 1px;
      padding-bottom: 1px;
    }*/ }
  @media screen and (min-width: 768px) {
    .view-content-features-grid .views-row {
      padding-right: 1px;
      padding-bottom: 1px; } }

.view-content-features-grid .node.feature.grid {
  background: #f7f7f7; }

.node.feature.carousel .text {
  padding-bottom: 48px; }
  @media screen and (min-width: 768px) {
    .node.feature.carousel .text {
      max-width: 50%;
      padding-left: 30px;
      padding-bottom: 80px; } }

.node.feature.carousel h4 {
  font-size: 20px;
  margin-bottom: .5em; }
  @media screen and (min-width: 768px) {
    .node.feature.carousel h4 {
      font-size: 30px; } }

.node.feature.carousel div {
  line-height: 1.3; }
  @media screen and (min-width: 768px) {
    .node.feature.carousel div {
      line-height: inherit; } }

.node.feature.carousel .field-name-field-feature-subtitle {
  font-size: 18px; }

.node.feature.grid h4 {
  font-family: "1898Sans-Regular", sans-serif; }

.node.feature.grid .text {
  left: 0; }

/* Home See More links */
.mcc-homepage a.more-link {
  font-size: 12px; }
  .mcc-homepage a.more-link i {
    font-size: .8em;
    padding-left: .2em; }

.mcc-homepage h2 a, .mcc-homepage h3 a {
  /*&:link, &:visited {
        color: $color-blue;
      }
      &:hover, &:active {
        color: $wcm-dark-gray;
      }*/ }

/* Home News */
.view-content-home-news.contextual-links-region {
  position: static; }

.view-content-home-news .view-footer a.more-link {
  position: absolute;
  right: 20px;
  bottom: 10px; }

@media screen and (min-width: 992px) {
  .view-content-home-news .views-row-last {
    display: none; } }

@media screen and (min-width: 1140px) {
  .view-content-home-news .views-row-last {
    display: block; } }

/* News Item */
.home-news-item .side.left {
  display: none; }
  @media screen and (min-width: 768px) {
    .home-news-item .side.left {
      display: block;
      margin: 0 1em 2em 0;
      float: left; } }
  @media screen and (min-width: 992px) {
    .home-news-item .side.left {
      display: block;
      margin: 0 1em 2em 0;
      float: left;
      width: 34%; } }
  .home-news-item .side.left figcaption {
    display: none; }

.home-news-item .side.right {
  font-size: 14px;
  padding-bottom: 1.65em; }
  .home-news-item .side.right span.date-display-single {
    padding: 0;
    font-size: 13px; }
  .home-news-item .side.right h3 a:after {
    line-height: 2.5; }
  @media screen and (min-width: 768px) {
    .home-news-item .side.right {
      width: 60%;
      margin-left: 20px;
      float: left; } }
  @media screen and (min-width: 992px) {
    .home-news-item .side.right {
      font-size: 14px;
      margin-left: 0;
      width: 60%;
      float: right; } }
  @media screen and (min-width: 1140px) {
    .home-news-item .side.right {
      font-size: 14px; } }
  .home-news-item .side.right * {
    margin: 0;
    font-size: inherit;
    line-height: inherit; }
  .home-news-item .side.right a.more-link {
    display: inline-block;
    text-align: left;
    font-size: 12px; }

.home-news-item .field-name-field-news-date {
  font-style: italic;
  color: #a2998b; }

/* Home Funding Opportunities */
.view-content-home-funding-opportunities.contextual-links-region {
  position: static; }

.view-content-home-funding-opportunities .view-footer a.more-link {
  position: absolute;
  right: 20px;
  bottom: 10px; }

@media screen and (min-width: 768px) {
  .view-content-home-funding-opportunities .views-row-last {
    display: none; } }

@media screen and (min-width: 1200px) {
  .view-content-home-funding-opportunities .views-row-last {
    display: block; } }

.home-funding-opportunity {
  font-size: 14px; }
  div.panel-pane .home-funding-opportunity.node {
    padding-bottom: 1.65em; }
  @media screen and (min-width: 992px) {
    .home-funding-opportunity {
      font-size: 14px; } }
  @media screen and (min-width: 1200px) {
    .home-funding-opportunity {
      font-size: 14px; } }
  .home-funding-opportunity * {
    margin: 0;
    font-size: inherit;
    line-height: inherit; }
  .home-funding-opportunity a.more-link {
    display: inline-block;
    text-align: left;
    font-size: 12px; }

/* Homepage Calendar */
.pane-home-calendar:before, .pane-home-calendar:after {
  content: " ";
  display: table; }

.pane-home-calendar:after {
  clear: both; }

@media screen and (min-width: 768px) {
  .pane-home-calendar .pane-title {
    min-height: 0; } }

.pane-home-calendar .cell {
  width: 49.90%;
  float: left;
  min-height: 176px;
  position: relative;
  padding: 10px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }
  @media screen and (min-width: 768px) {
    .pane-home-calendar .cell {
      min-height: 235px;
      padding-top: 20px;
      padding-left: 25px; } }
  @media screen and (min-width: 992px) {
    .flexbox .pane-home-calendar .cell {
      position: absolute;
      height: 50%; }
      .flexbox .pane-home-calendar .cell .cell-inner {
        position: relative;
        height: 100%;
        padding-bottom: 1.5em; } }
  @media screen and (min-width: 1140px) {
    .pane-home-calendar .cell {
      min-height: 257px; } }

.pane-home-calendar .cell-one {
  background: #f7f7f7;
  padding-right: 20px; }
  .pane-home-calendar .cell-one:after {
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    visibility: visible; }
  .pane-home-calendar .cell-one:after {
    border-width: 10px;
    border-left-color: #f7f7f7;
    right: -20px;
    top: 50%;
    margin-top: -10px; }
  @media screen and (min-width: 768px) {
    .pane-home-calendar .cell-one:after {
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      visibility: visible; }
    .pane-home-calendar .cell-one:after {
      border-width: 17px;
      border-left-color: #f7f7f7;
      right: -34px;
      top: 50%;
      margin-top: -17px; } }
  .flexbox .pane-home-calendar .cell-one {
    top: 0;
    left: 0; }
  .pane-home-calendar .cell-one a.more-link {
    position: absolute;
    bottom: 5px;
    right: 5px; }
    @media screen and (min-width: 768px) {
      .pane-home-calendar .cell-one a.more-link {
        bottom: 0;
        right: 0; } }

.flexbox .pane-home-calendar .cell-two {
  top: 0;
  right: 0; }

.pane-home-calendar .cell-four {
  background: #f7f7f7; }
  .pane-home-calendar .cell-four:after {
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    visibility: visible; }
  .pane-home-calendar .cell-four:after {
    border-width: 10px;
    border-right-color: #f7f7f7;
    left: -20px;
    top: 50%;
    margin-top: -10px; }
  @media screen and (min-width: 768px) {
    .pane-home-calendar .cell-four:after {
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      visibility: visible; }
    .pane-home-calendar .cell-four:after {
      border-width: 17px;
      border-right-color: #f7f7f7;
      left: -34px;
      top: 50%;
      margin-top: -17px; } }
  @media screen and (min-width: 1200px) {
    .pane-home-calendar .cell-four {
      padding-top: 20px; } }
  .flexbox .pane-home-calendar .cell-four {
    bottom: 0;
    right: 0; }
  .pane-home-calendar .cell-four a.more-link {
    position: absolute;
    bottom: 5px;
    right: 5px; }
    @media screen and (min-width: 768px) {
      .pane-home-calendar .cell-four a.more-link {
        bottom: 0;
        right: 0; } }

.pane-home-calendar .cell-selected-date {
  text-align: center;
  padding: 10px; }
  .pane-home-calendar .cell-selected-date .selected-date {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0; }
    .pane-home-calendar .cell-selected-date .selected-date:before {
      content: '';
      display: inline-block;
      height: 100%;
      vertical-align: middle;
      margin-right: -0.25em;
      /* Adjusts for spacing */ }
  .pane-home-calendar .cell-selected-date .selected-date .content {
    display: inline-block;
    vertical-align: middle; }
  .pane-home-calendar .cell-selected-date span.day {
    display: block;
    color: #b31b1b;
    font-size: 100px;
    font-size: 10rem;
    line-height: 10rem; }
  .pane-home-calendar .cell-selected-date span.month {
    display: block;
    font-size: 20px;
    font-size: 2rem; }
  .flexbox .pane-home-calendar .cell-selected-date {
    bottom: 0;
    left: 0; }

.pane-home-calendar .event h4 {
  font-size: 12px;
  font-weight: normal;
  margin: 0 auto 1em auto; }
  @media screen and (min-width: 768px) {
    .pane-home-calendar .event h4 {
      font-size: 14px; } }
  .pane-home-calendar .event h4 a {
    display: block; }
    .pane-home-calendar .event h4 a:after {
      line-height: 1.5; }

#calendar-events-data {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px); }

#home-calendar .clndr {
  margin: 0px 5px;
  line-height: 1em;
  font-size: 12px;
  font-weight: bold; }
  @media screen and (min-width: 768px) {
    #home-calendar .clndr {
      font-size: 14px;
      margin: 4px auto 4px auto; } }
  #home-calendar .clndr .clndr-controls {
    width: 100%;
    position: relative;
    margin-bottom: 10px; }
    #home-calendar .clndr .clndr-controls:before, #home-calendar .clndr .clndr-controls:after {
      content: " ";
      display: table; }
    #home-calendar .clndr .clndr-controls:after {
      clear: both; }
    #home-calendar .clndr .clndr-controls .month {
      text-align: center;
      color: #b31b1b; }
      @media screen and (min-width: 768px) {
        #home-calendar .clndr .clndr-controls .month {
          font-size: 12px; } }
    #home-calendar .clndr .clndr-controls .clndr-control-button {
      display: block;
      overflow: visible;
      position: absolute;
      height: 20px;
      width: 10px;
      top: 0;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      cursor: pointer; }
      #home-calendar .clndr .clndr-controls .clndr-control-button.inactive {
        opacity: 0.5; }
        #home-calendar .clndr .clndr-controls .clndr-control-button.inactive:hover {
          background: none;
          cursor: default; }
      #home-calendar .clndr .clndr-controls .clndr-control-button span {
        display: none; }
      #home-calendar .clndr .clndr-controls .clndr-control-button.clndr-previous-button {
        left: 10px; }
        #home-calendar .clndr .clndr-controls .clndr-control-button.clndr-previous-button:after {
          border: solid transparent;
          content: " ";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
          visibility: visible; }
        #home-calendar .clndr .clndr-controls .clndr-control-button.clndr-previous-button:after {
          border-width: 7px;
          border-right-color: #000000;
          left: -14px; }
        @media screen and (min-width: 768px) {
          #home-calendar .clndr .clndr-controls .clndr-control-button.clndr-previous-button:after {
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
            visibility: visible; }
          #home-calendar .clndr .clndr-controls .clndr-control-button.clndr-previous-button:after {
            border-width: 10px;
            border-right-color: #000000;
            left: -20px; } }
        #home-calendar .clndr .clndr-controls .clndr-control-button.clndr-previous-button:after {
          left: -10px; }
      #home-calendar .clndr .clndr-controls .clndr-control-button.clndr-next-button {
        right: 10px; }
        #home-calendar .clndr .clndr-controls .clndr-control-button.clndr-next-button:after {
          border: solid transparent;
          content: " ";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
          visibility: visible; }
        #home-calendar .clndr .clndr-controls .clndr-control-button.clndr-next-button:after {
          border-width: 7px;
          border-left-color: #000000;
          right: -14px; }
        @media screen and (min-width: 768px) {
          #home-calendar .clndr .clndr-controls .clndr-control-button.clndr-next-button:after {
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
            visibility: visible; }
          #home-calendar .clndr .clndr-controls .clndr-control-button.clndr-next-button:after {
            border-width: 10px;
            border-left-color: #000000;
            right: -20px; } }
        #home-calendar .clndr .clndr-controls .clndr-control-button.clndr-next-button:after {
          right: -10px; }
  #home-calendar .clndr .clndr-table {
    table-layout: fixed;
    width: 100%;
    margin: 0; }
    #home-calendar .clndr .clndr-table tr {
      height: 19px; }
      @media screen and (max-width: 991px) {
        #home-calendar .clndr .clndr-table tr {
          padding: 0;
          height: 23px; } }
      @media screen and (min-width: 992px) {
        #home-calendar .clndr .clndr-table tr {
          padding: 0;
          height: 24px; } }
      @media screen and (min-width: 1200px) {
        #home-calendar .clndr .clndr-table tr {
          padding: 0;
          height: 27px; } }
    #home-calendar .clndr .clndr-table .header-days .header-day {
      vertical-align: middle;
      text-align: center;
      border-left: none;
      border-top: none; }
      #home-calendar .clndr .clndr-table .header-days .header-day:last-child {
        border-right: none; }
    #home-calendar .clndr .clndr-table tbody {
      border-top: none; }
    #home-calendar .clndr .clndr-table tr td {
      vertical-align: middle;
      padding: 0; }
    #home-calendar .clndr .clndr-table tr .day {
      border-left: none;
      border-top: none;
      width: 100%;
      height: inherit;
      cursor: default;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none; }
      #home-calendar .clndr .clndr-table tr .day .day-contents {
        text-align: center;
        position: relative; }
      #home-calendar .clndr .clndr-table tr .day .marker {
        display: none;
        position: absolute;
        background: #b31b1b;
        bottom: -5px;
        left: 50%;
        width: 4px;
        height: 4px;
        border-radius: 2px;
        margin-left: -2px; }
        @media screen and (min-width: 1200px) {
          #home-calendar .clndr .clndr-table tr .day .marker {
            bottom: -7px;
            width: 6px;
            height: 6px;
            border-radius: 3px;
            margin-left: -3px; } }
      #home-calendar .clndr .clndr-table tr .day.event {
        cursor: pointer; }
        #home-calendar .clndr .clndr-table tr .day.event:hover {
          background: #dddddd; }
        #home-calendar .clndr .clndr-table tr .day.event .marker {
          display: block;
          background: #b31b1b; }
      #home-calendar .clndr .clndr-table tr .day.past.event .marker {
        background: #dddddd; }
      #home-calendar .clndr .clndr-table tr .day.selected {
        background: #b31b1b;
        color: #fff; }
        #home-calendar .clndr .clndr-table tr .day.selected:hover {
          background: #b31b1b; }
        #home-calendar .clndr .clndr-table tr .day.selected.event .marker {
          display: none; }
      #home-calendar .clndr .clndr-table tr .day.inactive {
        background: transparent; }
      #home-calendar .clndr .clndr-table tr .day:last-child {
        border-right: none; }
    #home-calendar .clndr .clndr-table tr .empty, #home-calendar .clndr .clndr-table tr .adjacent-month {
      border-left: none;
      border-top: none;
      width: 100%;
      height: inherit;
      background: transparent; }
      #home-calendar .clndr .clndr-table tr .empty:last-child, #home-calendar .clndr .clndr-table tr .adjacent-month:last-child {
        border-right: none; }
    #home-calendar .clndr .clndr-table tr:last-child .day {
      border-bottom: none; }
    #home-calendar .clndr .clndr-table tr:last-child .empty {
      border-bottom: none; }

/* Interactive Calendar (Calendar page) */
.pane-interactive-calendar h3#calendar-title {
  text-transform: uppercase;
  font-size: 28px;
  margin: 2em auto; }
  @media screen and (min-width: 992px) {
    .pane-interactive-calendar h3#calendar-title {
      margin: 1.8em auto;
      font-size: 34px; } }

#calendar-events {
  margin: 1em auto; }

.events-list.full-page .event .image {
  display: none;
  margin-bottom: 1.5em; }
  @media screen and (min-width: 992px) {
    .events-list.full-page .event .image {
      display: block; } }

.events-list.full-page .event .header:before, .events-list.full-page .event .header:after {
  content: " ";
  display: table; }

.events-list.full-page .event .header:after {
  clear: both; }

.events-list.full-page .event h3 {
  margin: 0 0 0 2.35em;
  padding: 0 0 .15em 1em;
  border-left: 1px solid #dddddd;
  color: #b31b1b;
  font-family: "1898Sans-Regular", sans-serif;
  font-weight: normal;
  font-size: 30px; }
  .events-list.full-page .event h3 a {
    display: block;
    color: inherit; }
    .events-list.full-page .event h3 a:link, .events-list.full-page .event h3 a:visited {
      color: inherit;
      text-decoration: none; }

.events-list.full-page .event .description {
  margin: 1.5em auto; }

.events-list.full-page .event .time, .events-list.full-page .event .location {
  line-height: 1.25; }

.events-list.full-page .event {
  margin-bottom: 1em; }
  @media screen and (min-width: 768px) {
    .events-list.full-page .event {
      margin-bottom: 3em; }
      .events-list.full-page .event:before, .events-list.full-page .event:after {
        content: " ";
        display: table; }
      .events-list.full-page .event:after {
        clear: both; }
      .events-list.full-page .event .side {
        float: left; }
        .events-list.full-page .event .side.left {
          width: 17%;
          min-height: 1px;
          padding: 0 .5em 0 0; }
        .events-list.full-page .event .side.right {
          padding: 0 7em 0 2.8em;
          width: 83%; } }
  .events-list.full-page .event .side.right {
    padding-bottom: 1em;
    border-bottom: 1px solid #dddddd; }
    @media screen and (min-width: 768px) {
      .events-list.full-page .event .side.right {
        padding-bottom: 3em; } }
  .events-list.full-page .event.last .side.right {
    border-bottom: none; }

.dk-initialized label {
  margin-bottom: 0; }

.dk-select.form-control {
  height: 0;
  max-width: none;
  border: none;
  padding: 0; }

#calendar-controls {
  margin-top: 2em; }
  #calendar-controls a.next-month-link {
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px); }
  #calendar-controls .year {
    font-size: 18px; }
  #calendar-controls .month-selector-container {
    margin-bottom: 10px; }
    #calendar-controls .month-selector-container.dk-initialized i {
      top: auto;
      bottom: .75em;
      right: -10px;
      font-size: 20px; }
      #calendar-controls .month-selector-container.dk-initialized i:after {
        font-family: "fontello";
        font-style: normal;
        font-weight: normal;
        speak: none;
        display: inline-block;
        text-decoration: inherit;
        width: 1em;
        margin-right: .2em;
        text-align: center;
        font-variant: normal;
        text-transform: none;
        line-height: 1em;
        margin-left: .2em;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        content: ""; }
      #calendar-controls .month-selector-container.dk-initialized i:hover:after, #calendar-controls .month-selector-container.dk-initialized i:active:after {
        text-decoration: none; }
    #calendar-controls .month-selector-container .dk-selected {
      color: #b31b1b;
      font-size: 36px;
      margin: 0 0 5px 0;
      padding: 0 .5em 0 0; }
    #calendar-controls .month-selector-container .dk-select-options {
      border-top: 1px solid #000000; }
  #calendar-controls .clndr-grid {
    padding: 0 11.11%;
    margin-bottom: .75em;
    position: relative; }
    @media screen and (min-width: 992px) {
      #calendar-controls .clndr-grid {
        padding: 0 80px; } }
    @media screen and (min-width: 1200px) {
      #calendar-controls .clndr-grid {
        padding: 0 70px; } }
    #calendar-controls .clndr-grid .bx-controls a {
      position: absolute;
      display: block;
      top: 0;
      bottom: 10px;
      width: 11.11%;
      height: auto;
      background: #666666;
      text-indent: -999px;
      overflow: hidden; }
      @media screen and (min-width: 992px) {
        #calendar-controls .clndr-grid .bx-controls a {
          width: 80px; } }
      @media screen and (min-width: 1200px) {
        #calendar-controls .clndr-grid .bx-controls a {
          width: 70px; } }
      #calendar-controls .clndr-grid .bx-controls a:hover {
        background: #b31b1b; }
      #calendar-controls .clndr-grid .bx-controls a.bx-prev {
        left: 0; }
        #calendar-controls .clndr-grid .bx-controls a.bx-prev:after {
          border: solid transparent;
          content: " ";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
          visibility: visible; }
        #calendar-controls .clndr-grid .bx-controls a.bx-prev:after {
          border-width: 10px;
          border-right-color: #fff;
          left: -20px;
          top: 50%;
          margin-top: -10px; }
        #calendar-controls .clndr-grid .bx-controls a.bx-prev:after {
          left: 50%;
          margin-left: -15px; }
      #calendar-controls .clndr-grid .bx-controls a.bx-next {
        right: 0;
        border-left: 1px solid #f7f7f7; }
        #calendar-controls .clndr-grid .bx-controls a.bx-next:after {
          border: solid transparent;
          content: " ";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
          visibility: visible; }
        #calendar-controls .clndr-grid .bx-controls a.bx-next:after {
          border-width: 10px;
          border-left-color: #fff;
          right: -20px;
          top: 50%;
          margin-top: -10px; }
        #calendar-controls .clndr-grid .bx-controls a.bx-next:after {
          right: 50%;
          margin-right: -15px; }
  #calendar-controls .calendar-filters .filter-viewby .dk-option[data-value="all"] {
    display: none; }
  #calendar-controls .days:before, #calendar-controls .days:after {
    content: " ";
    display: table; }
  #calendar-controls .days:after {
    clear: both; }
  #calendar-controls .day {
    float: left;
    text-align: center;
    width: 80px; }
    #calendar-controls .day .inner {
      background: #f7f7f7;
      border-left: 1px solid #f7f7f7;
      margin-bottom: 10px;
      padding: 10px 0;
      position: relative; }
    #calendar-controls .day.today .inner {
      color: #b31b1b;
      background: #f7f7f7;
      font-weight: 900; }
    #calendar-controls .day.event {
      cursor: pointer; }
      #calendar-controls .day.event .inner {
        background: #b31b1b;
        color: #fff; }
        #calendar-controls .day.event .inner:after {
          border: solid transparent;
          content: " ";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
          visibility: visible; }
        #calendar-controls .day.event .inner:after {
          border-width: 10px;
          border-top-color: #b31b1b;
          bottom: -20px;
          right: 50%;
          margin-right: -10px; }
      #calendar-controls .day.event:hover .inner {
        background: #666666; }
        #calendar-controls .day.event:hover .inner:after {
          border: solid transparent;
          content: " ";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
          visibility: visible; }
        #calendar-controls .day.event:hover .inner:after {
          border-width: 10px;
          border-top-color: #666666;
          bottom: -20px;
          right: 50%;
          margin-right: -10px; }
      #calendar-controls .day.event.selected .inner {
        background: #b31b1b;
        color: #fff; }
        #calendar-controls .day.event.selected .inner:after {
          border: solid transparent;
          content: " ";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
          visibility: visible; }
        #calendar-controls .day.event.selected .inner:after {
          border-width: 10px;
          border-top-color: #b31b1b;
          bottom: -20px;
          right: 50%;
          margin-right: -10px; }
    #calendar-controls .day.event.past .inner {
      background: #666666;
      color: #fff; }
      #calendar-controls .day.event.past .inner:after {
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        visibility: visible; }
      #calendar-controls .day.event.past .inner:after {
        border-width: 10px;
        border-top-color: #666666;
        bottom: -20px;
        right: 50%;
        margin-right: -10px; }
    #calendar-controls .day.event.past:hover .inner {
      background: #555555; }
      #calendar-controls .day.event.past:hover .inner:after {
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        visibility: visible; }
      #calendar-controls .day.event.past:hover .inner:after {
        border-width: 10px;
        border-top-color: #555555;
        bottom: -20px;
        right: 50%;
        margin-right: -10px; }
    #calendar-controls .day.event.past.selected .inner {
      background: #555555; }
      #calendar-controls .day.event.past.selected .inner:after {
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        visibility: visible; }
      #calendar-controls .day.event.past.selected .inner:after {
        border-width: 10px;
        border-top-color: #555555;
        bottom: -20px;
        right: 50%;
        margin-right: -10px; }
    #calendar-controls .day.event.today .inner {
      background: #b31b1b;
      color: #fff; }
      #calendar-controls .day.event.today .inner:after {
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        visibility: visible; }
      #calendar-controls .day.event.today .inner:after {
        border-width: 10px;
        border-top-color: #b31b1b;
        bottom: -20px;
        right: 50%;
        margin-right: -10px; }
    #calendar-controls .day.event.today:hover .inner {
      background: #666666; }
      #calendar-controls .day.event.today:hover .inner:after {
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        visibility: visible; }
      #calendar-controls .day.event.today:hover .inner:after {
        border-width: 10px;
        border-top-color: #666666;
        bottom: -20px;
        right: 50%;
        margin-right: -10px; }
    #calendar-controls .day span {
      display: block; }
    #calendar-controls .day .day-of-week {
      font-size: 14px;
      text-transform: uppercase; }
    #calendar-controls .day .day-number {
      font-size: 14px; }

/* Date styling */
/* Used by both Calendar page and News page */
.event-date {
  float: left;
  text-align: center; }
  .event-date .year {
    display: none; }
  .event-date .month {
    display: block;
    text-transform: uppercase;
    border-bottom: 3px solid #b31b1b;
    font-size: 18px;
    font-size: 1.8rem; }
  .event-date .day {
    display: block;
    font-size: 34px;
    font-size: 3.4rem;
    line-height: 4rem; }

/* News */
span.date-display-single {
  font-family: "1898Sans-Italic", sans-serif;
  padding: 20px 0;
  display: block; }

.view-content-news {
  margin: 1.8em 0; }
  .view-content-news h3 {
    margin: 0 0 1em 2.35em;
    padding: 0 0 .15em 1em;
    border-left: 1px solid #dddddd;
    font-family: "1898Sans-Regular", sans-serif;
    font-size: 22px;
    font-size: 2.2rem;
    min-height: 3.5em; }
    @media screen and (min-width: 768px) {
      .view-content-news h3 {
        font-size: 30px;
        font-size: 3rem;
        min-height: 2em; } }
    .view-content-news h3 a {
      display: block; }
      .view-content-news h3 a:after {
        content: ''; }
      .view-content-news h3 a:link, .view-content-news h3 a:visited {
        text-decoration: none; }
  .view-content-news .views-row {
    margin: 1.8em 0; }
    .view-content-news .views-row .side.left {
      display: none; }
    @media screen and (min-width: 768px) {
      .view-content-news .views-row:before, .view-content-news .views-row:after {
        content: " ";
        display: table; }
      .view-content-news .views-row:after {
        clear: both; }
      .view-content-news .views-row .side {
        float: left; }
        .view-content-news .views-row .side.left {
          display: block;
          width: 17%;
          min-height: 1px;
          padding: 0 .5em 0 0; }
        .view-content-news .views-row .side.right {
          padding: 0 0.9em 0 1.8em;
          width: 83%; } }
    .view-content-news .views-row .side.right {
      padding-bottom: 1em;
      border-bottom: 1px solid #dddddd; }
      @media screen and (min-width: 768px) {
        .view-content-news .views-row .side.right {
          padding-bottom: 3em; } }
    .view-content-news .views-row.views-row-last .side.right {
      border-bottom: none; }
    .view-content-news .views-row a.more-link {
      display: inline;
      white-space: nowrap; }

/* Generic for Panelizer Full Content ovveride of nodes */
/* First applied to news_item nodes */
.page-node .node .field-name-field-featured-image, .page-node .panel-display .field-name-field-featured-image {
  float: left;
  max-width: 25%;
  margin: .25em 1.5em .25em 0; }
  .page-node .node .field-name-field-featured-image img, .page-node .panel-display .field-name-field-featured-image img {
    width: inherit;
    max-width: 100%;
    float: none;
    margin: 0; }

.page-node .node .pane-node-field-news-date, .page-node .node .field-name-field-news-date, .page-node .panel-display .pane-node-field-news-date, .page-node .panel-display .field-name-field-news-date {
  font-style: italic;
  color: #555555; }
  @media screen and (min-width: 768px) {
    .page-node .node .pane-node-field-news-date, .page-node .node .field-name-field-news-date, .page-node .panel-display .pane-node-field-news-date, .page-node .panel-display .field-name-field-news-date {
      margin-bottom: -rhythm(1); } }

/* Views Responsive Grid for */
/* Research Programs, Shared Resources etc */
.mcc-content-grid {
  margin: 2em auto; }
  @media screen and (min-width: 992px) {
    .mcc-content-grid .views-row {
      display: block;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-wrap: wrap;
      flex-wrap: wrap;
      margin: 0 -28px; } }
  .css-test-colors .mcc-content-grid .views-row {
    background: green; }
  .mcc-content-grid .views-row:before, .mcc-content-grid .views-row:after {
    content: " ";
    display: table; }
  .mcc-content-grid .views-row:after {
    clear: both; }
  @media screen and (min-width: 992px) {
    .mcc-content-grid .views-responsive-grid.views-columns-2 {
      margin-right: 85px; } }
  .mcc-content-grid .views-responsive-grid.views-columns-2 .views-column {
    padding-bottom: 5px;
    margin-bottom: 30px;
    border-bottom: 1px solid #dddddd; }
    @media screen and (min-width: 1200px) {
      .mcc-content-grid .views-responsive-grid.views-columns-2 .views-column {
        margin-bottom: 3em;
        padding-bottom: 0;
        border-bottom: none;
        float: left;
        width: 50%; }
        .css-test-colors .mcc-content-grid .views-responsive-grid.views-columns-2 .views-column {
          background: purple;
          border-left: 1px solid yellow; }
        .mcc-content-grid .views-responsive-grid.views-columns-2 .views-column.views-column-first {
          border-left: none; }
        .mcc-content-grid .views-responsive-grid.views-columns-2 .views-column > article, .mcc-content-grid .views-responsive-grid.views-columns-2 .views-column > div {
          padding-left: 28px;
          padding-right: 24px; } }
  .mcc-content-grid .views-responsive-grid.views-columns-3 .views-column {
    padding-bottom: 15px;
    margin-bottom: 30px;
    border-bottom: 1px solid #dddddd; }
    @media screen and (min-width: 1200px) {
      .mcc-content-grid .views-responsive-grid.views-columns-3 .views-column {
        margin-bottom: 3em;
        padding-bottom: 0;
        border-bottom: none;
        float: left;
        width: 33.33%;
        border-left: 1px solid #dddddd; }
        .css-test-colors .mcc-content-grid .views-responsive-grid.views-columns-3 .views-column {
          background: purple;
          border-left: 1px solid yellow; }
        .mcc-content-grid .views-responsive-grid.views-columns-3 .views-column.views-column-first {
          border-left: none; }
        .mcc-content-grid .views-responsive-grid.views-columns-3 .views-column > article, .mcc-content-grid .views-responsive-grid.views-columns-3 .views-column > div {
          padding-left: 28px;
          padding-right: 24px; } }
  .mcc-content-grid h2 {
    font-size: 280pxpx;
    font-size: 28pxrem; }
  .mcc-content-grid .field-type-image img {
    display: block; }
  .mcc-content-grid .field-name-body, .mcc-content-grid a.more-link {
    /*@include font-size(1.6);//, 22px); //, $base-font-size);
    @include breakpoint($md) {
      @include font-size(1.6); //, 22px); //$break-desktop-font-size);
    }
    @include break-wide {
      @include font-size(1.6); //, 22px); // $break-wide-font-size);
    }*/ }
    .mcc-content-grid .field-name-body .icon-open-arrow-right, .mcc-content-grid a.more-link .icon-open-arrow-right {
      font-size: 80%;
      padding-left: 5px; }
      .mcc-content-grid .field-name-body .icon-open-arrow-right:after, .mcc-content-grid a.more-link .icon-open-arrow-right:after {
        font-family: "fontello";
        font-style: normal;
        font-weight: normal;
        speak: none;
        display: inline-block;
        text-decoration: inherit;
        width: 1em;
        margin-right: .2em;
        text-align: center;
        font-variant: normal;
        text-transform: none;
        line-height: 1em;
        margin-left: .2em;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        content: ""; }
      .mcc-content-grid .field-name-body .icon-open-arrow-right:hover:after, .mcc-content-grid .field-name-body .icon-open-arrow-right:active:after, .mcc-content-grid a.more-link .icon-open-arrow-right:hover:after, .mcc-content-grid a.more-link .icon-open-arrow-right:active:after {
        text-decoration: none; }

/* External Links (field_external_links) */
/* Shared Resources, etc */
.mcc-content-grid .field-name-field-external-links {
  font-size: 16px; }
  @media screen and (min-width: 992px) {
    .mcc-content-grid .field-name-field-external-links {
      font-size: 16px; } }
  @media screen and (min-width: 1200px) {
    .mcc-content-grid .field-name-field-external-links {
      font-size: 16px; } }
  .mcc-content-grid .field-name-field-external-links .field-item a:after {
    font-size: .8em; }

/** Clinical Trials **/
/* (views within views, within views) */
.view-content-clinical-trials h2 {
  text-transform: uppercase;
  margin: 0.9em 0; }

.view-content-clinical-trials h4 {
  font-weight: normal; }

.view-content-clinical-trials .views-row.level-1 {
  margin-bottom: rhythm(1); }

.view-content-clinical-trials .views-responsive-grid:before, .view-content-clinical-trials .views-responsive-grid:after {
  content: " ";
  display: table; }

.view-content-clinical-trials .views-responsive-grid:after {
  clear: both; }

@media screen and (min-width: 768px) {
  .view-content-clinical-trials .views-responsive-grid > .views-column {
    float: left;
    padding-right: 1.5em; }
  .view-content-clinical-trials .views-responsive-grid.views-columns-2 > .views-column {
    width: 50%; }
  .view-content-clinical-trials .views-responsive-grid.views-columns-3 > .views-column {
    width: 33%; } }

.pane-clinical-trials-panel-pane-1 .view-clinical-trials {
  padding-top: 20px !important; }

.pane-clinical-trials-panel-pane-1 .views-row {
  padding: 15px 0 20px;
  border-bottom: 1px solid #dddddd; }
  .pane-clinical-trials-panel-pane-1 .views-row .trial-title {
    padding-bottom: 10px;
    font-size: 17px; }
  .pane-clinical-trials-panel-pane-1 .views-row .views-field {
    padding-bottom: 2px; }
    .pane-clinical-trials-panel-pane-1 .views-row .views-field .views-label {
      font-weight: 600; }
    .pane-clinical-trials-panel-pane-1 .views-row .views-field .field-content {
      display: inline; }
  .pane-clinical-trials-panel-pane-1 .views-row .views-field-field-trial-source-link {
    padding-top: 10px; }
  .pane-clinical-trials-panel-pane-1 .views-row h3 {
    margin-bottom: 15px;
    text-transform: lowercase; }
    .pane-clinical-trials-panel-pane-1 .views-row h3 a {
      text-transform: capitalize; }

.pane-clinical-trials-panel-pane-1 .views-field-field-trial-key-eligibility {
  font-size: 1.3rem;
  margin-bottom: 20px; }

.pane-clinical-trials-panel-pane-1 .form-item-field-trial-primary-investigator-value-selective .form-select {
  width: 100%;
  max-width: 100%; }
  @media screen and (min-width: 992px) {
    .pane-clinical-trials-panel-pane-1 .form-item-field-trial-primary-investigator-value-selective .form-select {
      width: 300px; } }

.pane-clinical-trials-panel-pane-1 .form-item-field-trial-category-target-id-selective .form-select {
  width: 100%;
  max-width: 100%; }
  @media screen and (min-width: 992px) {
    .pane-clinical-trials-panel-pane-1 .form-item-field-trial-category-target-id-selective .form-select {
      width: 300px; } }

.pane-clinical-trials-panel-pane-1 .views-submit-button {
  padding: 10px 0 20px 0 !important;
  width: 100%; }
  .pane-clinical-trials-panel-pane-1 .views-submit-button .form-submit {
    margin-top: 0 !important; }

.pane-clinical-trials-panel-pane-1 .btn-sml {
  background-color: #cf4520;
  color: #fff;
  border-radius: 10px;
  height: 30px;
  line-height: 28px;
  padding-bottom: 0;
  padding-top: 0;
  font-size: 13px; }

/* General Views */
.view:before, .view:after {
  content: " ";
  display: table; }

.view:after {
  clear: both; }

/* Two Column Table View */
/* Funding Opporunities, Research Opportunities */
.view.two-column-list {
  margin: 1em auto; }
  @media screen and (min-width: 992px) {
    .view.two-column-list {
      border-top: none;
      margin: 4em auto; } }
  .view.two-column-list .node-teaser a.teaser-link:link, .view.two-column-list .node-teaser a.teaser-link:visited {
    color: inherit; }
  .view.two-column-list .node-teaser a.teaser-link:hover, .view.two-column-list .node-teaser a.teaser-link:active {
    text-decoration: none; }
  .view.two-column-list .view-header {
    color: #b31b1b;
    font-size: 24px;
    display: none; }
    @media screen and (min-width: 768px) {
      .view.two-column-list .view-header {
        display: block; } }
  .view.two-column-list .views-row a.teaser-link {
    display: block; }
    .view.two-column-list .views-row a.teaser-link:before, .view.two-column-list .views-row a.teaser-link:after {
      content: " ";
      display: table; }
    .view.two-column-list .views-row a.teaser-link:after {
      clear: both; }
  .view.two-column-list .view-header, .view.two-column-list .views-row a.teaser-link {
    padding: 1em .5em 1em .5em;
    border-bottom: 1px solid #dddddd; }
    .view.two-column-list .view-header:before, .view.two-column-list .view-header:after, .view.two-column-list .views-row a.teaser-link:before, .view.two-column-list .views-row a.teaser-link:after {
      content: " ";
      display: table; }
    .view.two-column-list .view-header:after, .view.two-column-list .views-row a.teaser-link:after {
      clear: both; }
    @media screen and (min-width: 768px) {
      .view.two-column-list .view-header, .view.two-column-list .views-row a.teaser-link {
        margin: 0;
        padding: 1em 0 1em .5em; } }
  @media screen and (min-width: 768px) {
    .view.two-column-list .side {
      float: left; }
      .view.two-column-list .side.left {
        width: 29%;
        padding-right: 2em; }
      .view.two-column-list .side.right {
        max-width: 71%;
        position: relative;
        padding-right: 4.5em; } }
  .view.two-column-list .side .link-arrow {
    padding-top: 1em;
    color: #cf4520; }
    @media screen and (min-width: 768px) {
      .view.two-column-list .side .link-arrow {
        padding-top: 0;
        display: block;
        width: 40px;
        height: 36px;
        position: absolute;
        right: 0;
        top: 50%;
        margin-top: -18px;
        bottom: 0;
        font-size: 380pxpx;
        font-size: 38pxrem;
        color: #555555; }
        .view.two-column-list .side .link-arrow span.mobile-text {
          display: none; } }
  .view.two-column-list h3 {
    margin-top: 0; }
  .view.two-column-list .views-row a.teaser-link {
    -webkit-transition: background 250ms;
    -o-transition: background 250ms;
    transition: background 250ms; }
    .view.two-column-list .views-row a.teaser-link .side .link-arrow {
      -webkit-transition: color 250ms;
      -o-transition: color 250ms;
      transition: color 250ms; }
    @media screen and (min-width: 992px) {
      .view.two-column-list .views-row a.teaser-link:hover {
        background: #f7f7f7; }
        .view.two-column-list .views-row a.teaser-link:hover .side .link-arrow {
          color: #959595; } }

/* Three Column Table View */
/* Members Directory */
.view.view-column-list {
  margin: 1.8em auto; }
  @media screen and (min-width: 992px) {
    .view.view-column-list {
      border-top: none;
      margin: 1.8em auto; } }
  .view.view-column-list .node-teaser a.teaser-link {
    display: block; }
    .view.view-column-list .node-teaser a.teaser-link:link, .view.view-column-list .node-teaser a.teaser-link:visited {
      color: inherit; }
    .view.view-column-list .node-teaser a.teaser-link:hover, .view.view-column-list .node-teaser a.teaser-link:active {
      text-decoration: none; }
    .view.view-column-list .node-teaser a.teaser-link:before, .view.view-column-list .node-teaser a.teaser-link:after {
      content: " ";
      display: table; }
    .view.view-column-list .node-teaser a.teaser-link:after {
      clear: both; }
  .view.view-column-list .view-header, .view.view-column-list .column-list-header {
    color: #b31b1b;
    font-size: 24px;
    display: none;
    margin-top: 1.8em; }
    .view.view-column-list .view-header:before, .view.view-column-list .view-header:after, .view.view-column-list .column-list-header:before, .view.view-column-list .column-list-header:after {
      content: " ";
      display: table; }
    .view.view-column-list .view-header:after, .view.view-column-list .column-list-header:after {
      clear: both; }
    @media screen and (min-width: 768px) {
      .view.view-column-list .view-header, .view.view-column-list .column-list-header {
        display: block; } }
  .view.view-column-list .views-row a.teaser-link {
    display: block; }
  .view.view-column-list .view-header, .view.view-column-list .column-list-header, .view.view-column-list .views-row, .view.view-column-list .view-empty {
    padding: 1.5em 0px;
    border-bottom: 1px solid #f7f7f7; }
    .view.view-column-list .view-header:before, .view.view-column-list .view-header:after, .view.view-column-list .column-list-header:before, .view.view-column-list .column-list-header:after, .view.view-column-list .views-row:before, .view.view-column-list .views-row:after, .view.view-column-list .view-empty:before, .view.view-column-list .view-empty:after {
      content: " ";
      display: table; }
    .view.view-column-list .view-header:after, .view.view-column-list .column-list-header:after, .view.view-column-list .views-row:after, .view.view-column-list .view-empty:after {
      clear: both; }
    @media screen and (min-width: 768px) {
      .view.view-column-list .view-header, .view.view-column-list .column-list-header, .view.view-column-list .views-row, .view.view-column-list .view-empty {
        margin: 0;
        padding: 1.5em 10px 1.5em 10px; } }
  .view.view-column-list .view-empty h2 {
    margin: 0; }
  @media screen and (min-width: 768px) {
    .view.view-column-list .side {
      float: left; }
      .view.view-column-list .side.left {
        width: 29%;
        padding-right: 2em; }
      .view.view-column-list .side.right {
        max-width: 71%;
        position: relative;
        padding-right: 40px; } }
  .view.view-column-list .side .link-arrow {
    display: none; }
    @media screen and (min-width: 768px) {
      .view.view-column-list .side .link-arrow {
        display: block;
        width: 40px;
        height: 40px;
        position: absolute;
        right: 0;
        top: 50%;
        margin-top: -20px;
        bottom: 0;
        font-size: 350pxpx;
        font-size: 35pxrem;
        color: #959595; } }
  @media screen and (min-width: 768px) {
    .view.view-column-list .column {
      float: left;
      min-height: 1px; }
    .view.view-column-list.views-columns-3 .column {
      width: 33%;
      padding-right: 1em; } }
  .view.view-column-list h3 {
    font-size: 20px;
    margin-top: 0; }
  @media screen and (min-width: 992px) {
    .view.view-column-list .views-row:hover {
      background: #f7f7f7; }
      .view.view-column-list .views-row:hover .side .link-arrow {
        color: #555555; } }

/* Pagination */
/* Views exposed filters */
/* Radio buttons (Research Opportunities) */
@media screen and (min-width: 768px) {
  .large-radio-buttons .views-exposed-widget > label {
    display: inline-block;
    margin-right: 1em;
    padding-left: 10px; }
  .large-radio-buttons .views-exposed-widget .views-widget {
    display: inline-block; } }

.large-radio-buttons div.form-type-radio {
  display: inline;
  font-size: 75%;
  margin-right: 5px; }
  @media screen and (min-width: 768px) {
    .large-radio-buttons div.form-type-radio {
      display: inline;
      font-size: 100%;
      margin-right: 10px; } }
  .large-radio-buttons div.form-type-radio input[type=radio] {
    display: none; }
  .large-radio-buttons div.form-type-radio label {
    display: inline-block;
    background: #e7751d;
    min-width: 5em;
    text-align: center;
    -webkit-transition: background 250ms;
    -o-transition: background 250ms;
    transition: background 250ms;
    color: #fff;
    font-size: 16px;
    line-height: 2.5em;
    cursor: pointer;
    padding: 0 15px;
    border-radius: 20px; }
    .large-radio-buttons div.form-type-radio label:hover {
      background: #b31b1b; }
  .large-radio-buttons div.form-type-radio input[type=radio][checked] + label {
    background-color: #b31b1b; }

/** MCC Panels Layouts **/
/** Panels Bottom **/
div.line-divider {
  position: absolute;
  left: 0;
  right: 0;
  height: 1px;
  border-top: 1px solid #f7f7f7;
  border-bottom: none;
  margin: 0; }

.mcc-layouts-bottom {
  min-height: 100px; }
  .mcc-homepage .mcc-layouts-bottom {
    margin: 0; }

/** Location Maps **/
/** Address Content **/
.address-wrap {
  text-align: center;
  margin: 1.8em 0; }
  .address-wrap .name {
    font-size: 20px;
    line-height: 26px;
    color: #555555;
    font-family: "1898Sans-Regular", sans-serif; }
    .address-wrap .name span {
      display: block; }
  .address-wrap .affiliation {
    font-size: 14px; }
  .address-wrap .address {
    font-size: 16px;
    color: #555555; }
  .address-wrap.color-cornell .name, .address-wrap.color-cornell .affiliation {
    color: #b31b1b; }
  .address-wrap.size-large .name {
    font-size: 34px; }
  .address-wrap.size-large .affiliation {
    font-size: 22px; }
  .address-wrap.size-large .address {
    font-size: 20px; }
  .address-wrap.style-alt .name, .address-wrap.style-alt .affiliation {
    color: #b31b1b; }
  .address-wrap.style-alt .address {
    font-family: inherit; }
  .address-wrap.align-center {
    text-align: center; }

.address-map-wrap {
  display: table; }
  .lt-ie9 .address-map-wrap {
    display: block;
    position: relative; }
    .lt-ie9 .address-map-wrap:before, .lt-ie9 .address-map-wrap:after {
      content: " ";
      display: table; }
    .lt-ie9 .address-map-wrap:after {
      clear: both; }
  .mcc-homepage .address-map-wrap {
    margin: 0; }
  @media screen and (min-width: 768px) {
    .address-map-wrap .side {
      display: table-cell;
      vertical-align: middle; }
      .address-map-wrap .side.left {
        width: 24%; }
      .address-map-wrap .side.right {
        width: 76%; } }
  .lt-ie9 .address-map-wrap .side {
    display: block;
    float: left;
    position: relative; }
    .lt-ie9 .address-map-wrap .side.left {
      min-height: 1px; }
    .lt-ie9 .address-map-wrap .side.right {
      min-height: 1px; }
  .address-map-wrap .location-map {
    margin: 0; }
    .address-map-wrap .location-map img {
      width: 100%; }
  @media screen and (min-width: 768px) {
    .address-map-wrap .address-wrap {
      padding: 0 1em; }
      .address-map-wrap .address-wrap:before {
        margin-right: 0; } }

/** Ctools Modal - Inline Panels Editor, etc **/
div.ctools-modal-content {
  font-size: 100%;
  line-height: 1.2; }

/**
 * Dropdown & Select styling
 **/
.views-attachment .container-inline {
  padding: 0.5em 0.5em 0 0 !important; }

.views-attachment .container-inline div, .views-attachment .container-inline label {
  float: left;
  width: 100%;
  margin: 0 0 5px 0; }
  @media screen and (min-width: 992px) {
    .views-attachment .container-inline div, .views-attachment .container-inline label {
      width: 14% !important;
      margin-right: 1em; } }

div#edit-role-wrapper,
div#edit-research-program-wrapper,
div#edit-field-member-physician-specialty-value-wrapper,
div#edit-name-wrapper {
  width: 100%; }
  @media screen and (min-width: 992px) {
    div#edit-role-wrapper,
    div#edit-research-program-wrapper,
    div#edit-field-member-physician-specialty-value-wrapper,
    div#edit-name-wrapper {
      width: 21%; } }

div#edit-name-wrapper label.infield {
  display: block !important; }

.view .dk-dropdown label, .view .views-exposed-form label, .view .views-attachment label, #calendar-controls .dk-dropdown label, #calendar-controls .views-exposed-form label, #calendar-controls .views-attachment label {
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 2.5em;
  font-weight: normal; }

.view .dk-dropdown, #calendar-controls .dk-dropdown {
  min-width: 6em;
  float: left;
  margin-right: 1em; }
  .view .dk-dropdown.filter-type, #calendar-controls .dk-dropdown.filter-type {
    min-width: 9em; }

.views-exposed-form .views-exposed-widget {
  padding: 0 1em 0 0; }

#edit-location-wrapper,
#edit-category-wrapper {
  width: 100%; }
  @media screen and (min-width: 992px) {
    #edit-location-wrapper,
    #edit-category-wrapper {
      width: auto; } }

.js .ctools-modal-content select, .js .ctools-modal-content ul.views-summary {
  visibility: visible; }

.dk-initialized {
  float: left;
  z-index: 5;
  position: relative;
  padding: .5em 0 0 0;
  margin-right: 1.5em; }
  .dk-initialized label {
    border-bottom: 1px solid #000000;
    text-align: left;
    display: block;
    padding-right: 25px;
    position: relative; }
  .dk-initialized i {
    font-size: 14px;
    vertical-align: middle;
    position: absolute;
    right: 0px;
    bottom: .5em;
    line-height: inherit; }
    .dk-initialized i:after {
      font-family: "fontello";
      font-style: normal;
      font-weight: normal;
      speak: none;
      display: inline-block;
      text-decoration: inherit;
      width: 1em;
      margin-right: .2em;
      text-align: center;
      font-variant: normal;
      text-transform: none;
      line-height: 1em;
      margin-left: .2em;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: ""; }
    .dk-initialized i:hover:after, .dk-initialized i:active:after {
      text-decoration: none; }
  .dk-initialized a.trigger, .dk-initialized div.dk-selected {
    position: absolute;
    visibility: visible !important; }
    .view-content-member-directory .dk-initialized a.trigger, .view-content-member-directory .dk-initialized div.dk-selected {
      display: none; }
    @media screen and (min-width: 768px) {
      .dk-initialized a.trigger, .dk-initialized div.dk-selected {
        display: block; } }
  .view-content-member-directory .dk-initialized div.dk-selected {
    display: none; }
  .view-content-member-directory .dk-initialized div.dk-selected, .pane-interactive-calendar .dk-initialized div.dk-selected {
    font-size: 12px;
    font-size: 1.2rem;
    margin: 3px 10px;
    padding: .5em;
    visibility: visible;
    color: #b31b1b; }
    @media screen and (min-width: 768px) {
      .view-content-member-directory .dk-initialized div.dk-selected, .pane-interactive-calendar .dk-initialized div.dk-selected {
        display: block;
        margin: 10px; } }
  .dk-initialized.no-label a.trigger, .dk-initialized.no-label div.dk-selected {
    position: static;
    visibility: visible; }
  .dk-initialized.item-list ul, .dk-initialized .dropcontainer ul, .dk-initialized .dk-select ul, .dk-initialized .dk-select-options.dk-select-options-highlight {
    visibility: visible;
    margin: 0;
    display: none;
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    overflow: auto !important;
    max-height: 500px !important;
    border: 1px solid #dddddd;
    border-top: none;
    padding: 10px;
    background: #fff; }
    .dk-initialized.item-list ul:before, .dk-initialized.item-list ul:after, .dk-initialized .dropcontainer ul:before, .dk-initialized .dropcontainer ul:after, .dk-initialized .dk-select ul:before, .dk-initialized .dk-select ul:after, .dk-initialized .dk-select-options.dk-select-options-highlight:before, .dk-initialized .dk-select-options.dk-select-options-highlight:after {
      content: " ";
      display: table; }
    .dk-initialized.item-list ul:after, .dk-initialized .dropcontainer ul:after, .dk-initialized .dk-select ul:after, .dk-initialized .dk-select-options.dk-select-options-highlight:after {
      clear: both; }
    @media screen and (max-width: 767px) {
      .dk-initialized.item-list ul, .dk-initialized .dropcontainer ul, .dk-initialized .dk-select ul, .dk-initialized .dk-select-options.dk-select-options-highlight {
        overflow: scroll !important;
        max-height: revert !important; } }
  .dk-initialized .dropcontainer ul li, .dk-initialized .dk-select ul li {
    margin: 0;
    padding: 0;
    list-style: none;
    list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7);
    font-size: 12px;
    font-size: 1.2rem;
    display: block;
    padding: .5em;
    color: #000000;
    cursor: pointer; }
    .dk-initialized .dropcontainer ul li:hover, .dk-initialized .dropcontainer ul li.dk-option-selected, .dk-initialized .dk-select ul li:hover, .dk-initialized .dk-select ul li.dk-option-selected {
      color: #b31b1b; }
  .dk-initialized.dk-opened {
    z-index: 999; }
    .dk-initialized.dk-opened ul.dk-select-options, .dk-initialized.dk-opened ul.views-summary {
      display: block;
      height: auto; }
  .dk-initialized .dk-select + select {
    display: none; }

.desktop-search-select.dk-initialized {
  margin: 0;
  padding: 0 1.75em 0 1em;
  line-height: 55px; }
  .desktop-search-select.dk-initialized div.dk-selected {
    position: static;
    visibility: visible;
    padding: 0 1em 0 0; }
  .desktop-search-select.dk-initialized i {
    right: 2.5em;
    bottom: 0; }
  .desktop-search-select.dk-initialized ul.dk-select-options {
    padding: .5em .5em .5em .5em;
    z-index: 10;
    margin-top: -2px;
    border: 2px solid;
    margin-left: -2px;
    margin-right: -2px;
    border-top: none; }

/* How You Can Help Button */
div.large-button-wrap {
  text-align: center; }

a.large-button {
  display: inline-block;
  font-size: 20px;
  text-align: center;
  padding: 0 50px;
  background: #e7751d;
  min-width: 100px;
  line-height: 3.05em;
  border-radius: 3px;
  -webkit-transition: all 250ms;
  -o-transition: all 250ms;
  transition: all 250ms; }
  a.large-button:link, a.large-button:visited {
    color: #fff; }
  a.large-button:hover, a.large-button:active {
    text-decoration: none;
    background: #b31b1b; }

/** Member Directory styling **/
/* Directory and Member Bio */
ul.profile-links-list {
  margin: 0; }
  ul.profile-links-list:before, ul.profile-links-list:after {
    content: " ";
    display: table; }
  ul.profile-links-list:after {
    clear: both; }
  ul.profile-links-list li {
    list-style: none;
    float: left;
    margin: 0 1em 0 0;
    padding: 0 1em 0 0;
    border-right: 2px solid #dddddd; }
    ul.profile-links-list li.last {
      padding-right: 0;
      border-right: none; }

/* Leadership - Member Bio */
.member-bio .side.left {
  padding-bottom: 1em; }

@media screen and (min-width: 768px) {
  .member-bio .side {
    float: left; }
    .member-bio .side.left {
      width: 21.5%;
      min-height: 1px;
      padding-right: 1.25em; }
    .member-bio .side.right {
      width: 78.5%;
      padding-left: 1.25em;
      padding-right: 5em; } }

.member-bio h2, .member-bio h3 {
  margin: 0 0 rhythm(0.2) 0;
  color: #b31b1b;
  font-size: 300pxpx;
  font-size: 30pxrem; }

.member-bio .field-name-field-member-department,
.member-bio .field-name-field-member-title {
  text-transform: uppercase;
  font-size: 180pxpx;
  font-size: 18pxrem;
  color: #555555; }

.member-bio .field-body {
  color: #555555; }

.member-bio .contact .field {
  font-size: 160pxpx;
  font-size: 16pxrem;
  color: #555555; }
  .member-bio .contact .field a:link, .member-bio .contact .field a:visited {
    color: inherit; }

.member-bio ul.profile-links-list {
  margin-top: rhythm(0.5);
  font-size: 160pxpx;
  font-size: 16pxrem; }

.view-content-leadership-members {
  margin: 3.75em 0; }
  .view-content-leadership-members .views-row {
    margin-top: 1em; }
    @media screen and (min-width: 768px) {
      .view-content-leadership-members .views-row {
        margin-top: 4.5em; } }
    .view-content-leadership-members .views-row .side.right {
      border-bottom: 1px solid #f7f7f7;
      padding-bottom: 1em; }
      @media screen and (min-width: 768px) {
        .view-content-leadership-members .views-row .side.right {
          padding-bottom: 4.5em; } }
    .view-content-leadership-members .views-row.views-row-first {
      margin-top: 0; }
    .view-content-leadership-members .views-row.views-row-last .side.right {
      border-bottom: none; }

.media-element-container {
  display: block;
  max-width: 100%; }
  @media screen and (min-width: 768px) {
    .media-element-container {
      display: inline-block;
      max-height: none;
      vertical-align: top; } }
  .media-element-container img {
    display: block;
    margin: 0;
    max-width: 100%;
    height: auto;
    vertical-align: bottom;
    max-height: 220px; }
    @media screen and (min-width: 768px) {
      .media-element-container img {
        max-height: none; } }
  .media-element-container span.field-name-field-file-image-caption {
    display: block;
    font-style: italic;
    font-weight: normal;
    font-size: 140pxpx;
    font-size: 14pxrem;
    margin: 9px 0; }
  .media-element-container.media-embed_floatleft {
    margin: .5em 0; }
    @media screen and (min-width: 992px) {
      .media-element-container.media-embed_floatleft {
        float: left;
        margin: .5em 2em 1em 0;
        max-width: 29%; } }
  .media-element-container.media-embed_floatright {
    margin: .5em 0; }
    @media screen and (min-width: 992px) {
      .media-element-container.media-embed_floatright {
        float: right;
        margin: .5em 0 1em 2em;
        max-width: 29%; } }
  .media-element-container.media-embed_oneoftwo {
    margin-top: 12px;
    margin-left: 17.5%;
    margin-right: -.25em;
    max-width: 32%; }
    @media screen and (min-width: 992px) {
      .media-element-container.media-embed_oneoftwo {
        margin-top: 20px; } }
    @media screen and (min-width: 1140px) {
      .media-element-container.media-embed_oneoftwo {
        margin-top: 25px; } }
  .media-element-container + .media-element-container.media-embed_oneoftwo {
    margin-left: 18px;
    margin-right: auto; }
  .media-element-container.media-embed_center {
    max-width: 75%;
    margin: 1em auto;
    display: block; }
    .media-element-container.media-embed_center img {
      margin: 0 auto; }
  .media-element-container.media-embed_fullwidth {
    max-width: 100%;
    margin: 1em 0;
    display: block; }

/* Education Page - Hack */
@media screen and (min-width: 768px) {
  .page-education .sidebar.information-column {
    padding-top: 2.4em; }
    .page-education .sidebar.information-column .panel-panel-inner {
      margin-top: 0; } }

/* Simple CSS3 fade in for some elements */
.js .hide-initially {
  opacity: 0;
  -webkit-transition: opacity 350ms;
  -o-transition: opacity 350ms;
  transition: opacity 350ms;
  -webkit-transition-delay: 100ms;
  transition-delay: 100ms; }

.hide-initially.make-visible {
  opacity: 1; }

/* Forms .. infield labels .. circle arrow rounded button .. etc */
form button[type="submit"] i {
  display: none; }

footer form.mcc-initialized h4 {
  padding-bottom: 8px; }

@media screen and (min-width: 768px) {
  footer form.mcc-initialized .mailchimp-newsletter-mergefields {
    float: left;
    margin-right: 10px; } }

@media screen and (max-width: 767px) {
  footer form.mcc-initialized .mailchimp-newsletter-mergefields .form-item {
    margin-bottom: 15px; } }

footer form.mcc-initialized .form-actions {
  margin-top: 0; }

form.mcc-initialized {
  /*.form-type-textfield {
    input[type="text"] {
      width: 100%;
      font-size: 16px;
      height: 2.8em;
      padding: 0 3em 0 1em;
      border: 1px solid $wcm-border-gray;
      border-radius: 0;
      -webkit-appearance: none;
      &::-ms-clear {
        display: none;
      }
    }
  }*/
  /* Newsletter signup */ }
  form.mcc-initialized.global-search span > div {
    display: inline !important; }
  form.mcc-initialized .form-item.infieldlabel, form.mcc-initialized .views-exposed-widget.infieldlabel {
    position: relative;
    display: inline-block; }
    form.mcc-initialized .form-item.infieldlabel label .form-required, form.mcc-initialized .views-exposed-widget.infieldlabel label .form-required {
      visibility: hidden; }
  .js form.mcc-initialized button[type="submit"] {
    -webkit-appearance: none;
    background: none;
    border: none;
    display: inline-block;
    vertical-align: middle;
    padding: 0 0 .25em 0;
    visibility: hidden; }
    .js form.mcc-initialized button[type="submit"].js-hide {
      display: none; }
  .wf-fontello-n4-active form.mcc-initialized button[type="submit"] span {
    display: none; }
  form.mcc-initialized button[type="submit"] i {
    display: inline;
    color: #4d4d4d;
    font-size: 1.8em;
    -webkit-transition: color 250ms;
    -o-transition: color 250ms;
    transition: color 250ms;
    display: none; }
  form.mcc-initialized button[type="submit"]:hover i, form.mcc-initialized button[type="submit"]:focus i {
    color: #dddddd; }
  .wf-fontello-n4-active form.mcc-initialized button[type="submit"] {
    visibility: visible; }
    .wf-fontello-n4-active form.mcc-initialized button[type="submit"] span {
      display: none; }
    .wf-fontello-n4-active form.mcc-initialized button[type="submit"] i {
      display: inline-block; }
  form.mcc-initialized.inline-submit-button {
    position: relative;
    display: inline-block; }
    form.mcc-initialized.inline-submit-button .form-type-textfield {
      margin: 0; }
    form.mcc-initialized.inline-submit-button button[type="submit"] {
      position: absolute;
      bottom: 0px;
      right: 0;
      padding-bottom: 0; }

.panel-onecol .col-md-12 .container-fluid {
  padding-left: 0;
  padding-right: 0; }

.panel-twocol .main-content .container-fluid, .panel-hero-3col .main-content .container-fluid {
  padding-left: 0;
  padding-right: 0; }

.hero-header {
  text-align: center;
  position: relative;
  margin-bottom: 20px; }
  .hero-header .title-wrap {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background: rgba(0, 0, 0, 0.35); }
    @media screen and (min-width: 768px) {
      .hero-header .title-wrap {
        background: -webkit-linear-gradient(bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0) 79%);
        background: -moz-linear-gradient(bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0) 79%);
        background: -o-linear-gradient(bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0) 79%);
        background: -ms-linear-gradient(bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0) 79%);
        background: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0) 79%); } }
    .hero-header .title-wrap:before {
      content: '';
      display: inline-block;
      height: 100%;
      vertical-align: middle;
      margin-right: -0.25em;
      /* Adjusts for spacing */ }
    .hero-header .title-wrap h1 {
      width: 98%;
      display: inline-block;
      vertical-align: middle;
      color: white;
      margin: .5em 0 0 0; }
      @media screen and (min-width: 992px) {
        .hero-header .title-wrap h1 {
          width: 80%; } }
      @media screen and (max-width: 767px) {
        .hero-header .title-wrap h1 {
          font-size: 2.222rem; } }

.node-type-research-program .pane-node-body div:nth-child(1) h3:first-child,
.panel-twocol .col-md-12 .pane-node-body div:nth-child(1) h3:first-child,
.panel-twocol .col-sm-12 .pane-node-body div:nth-child(1) h3:first-child,
.panel-twocol .col-md-9 .pane-node-body div:nth-child(1) h3:first-child {
  font-family: "1898Sans-Regular", sans-serif;
  line-height: 1.4; }

/* =Default Select Elements
----------------------------------------------------------*/
.form-select {
  max-width: none;
  height: 50px;
  border: 1px solid #dddddd;
  box-shadow: none;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath d='M207 381.5L12.7 187.1c-9.4-9.4-9.4-24.6 0-33.9l22.7-22.7c9.4-9.4 24.5-9.4 33.9 0l154.7 154 154.7-154c9.4-9.3 24.5-9.3 33.9 0l22.7 22.7c9.4 9.4 9.4 24.6 0 33.9L241 381.5c-9.4 9.3-24.6 9.3-34 0z' fill='%23cf4520'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 15px top 50%;
  background-size: 20px 20px;
  padding-right: 50px; }
